import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import { FaRupeeSign } from 'react-icons/fa';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import { CiEdit } from "react-icons/ci";

const StockAdd = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: uuidv4(),
    pname: '',
    pimage: '',
    topViewImage: '',
    sideViewImage: '',
    bottomViewImage: '',
    stylecode: '',
    discount: '',
    pprice: '',
    category: '',
    subcategory: '',
    validity: '',
    pcolor: '',
    quantity: '',
    stone: '',
  });
  const [showForm, setShowForm] = useState(false);
  const [productList, setProductList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [quantity, setQuantity] = useState(1); // Default quantity is 1
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    const storedProductList = JSON.parse(localStorage.getItem('productList')) || [];
    setProductList(storedProductList);
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/ap/subcategories');
      const uniqueCategories = response.data.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name === value.name
        ))
      );
      setCategories(uniqueCategories);

    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchSubcategories = async (categoryName) => {
    try {
      const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/subcategories', {
        params: { categoryName }
      });
      setSubcategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);


  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
      if (Array.isArray(response.data)) {
        setProductList(response.data);
        localStorage.setItem('productList', JSON.stringify(response.data));
      } else {
        console.error('API response is not an array');
      }
    } catch (err) {
      console.error('Failed to fetch products.', err);
    }
  };


  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSubcategoryChange = (e) => {
    setFormData({
      ...formData,
      subcategory: e.target.value,
    });
  };

  const uniqueCategories = [...new Set(categories.map(category => category.name))];
  const uniqueSubcategories = [...new Set(subcategories.map(subcategory => subcategory.subCategory))];


  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setFormData({ ...formData, category: selectedCategory, subcategory: '' });
    fetchSubcategories(selectedCategory);
  };

  // const handleChange = (e) => {
  //   const { id, value } = e.target;
  //   setFormData({ ...formData, [id]: value });
  // };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };


  const [pimage, setPImage] = useState(null);
  const [topViewImage, setTopViewImage] = useState(null);
  const [sideViewImage, setSideViewImage] = useState(null);
  const [bottomViewImage, setBottomViewImage] = useState(null);

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    switch (id) {
      case 'pimage':
        setPImage(files[0]);
        break;
      case 'topViewImage':
        setTopViewImage(files[0]);
        break;
      case 'sideViewImage':
        setSideViewImage(files[0]);
        break;
      case 'bottomViewImage':
        setBottomViewImage(files[0]);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('id', formData.id);
    formDataToSend.append('pname', formData.pname);
    formDataToSend.append('stylecode', formData.stylecode);
    formDataToSend.append('discount', formData.discount);
    formDataToSend.append('pprice', formData.pprice);
    formDataToSend.append('category', formData.category);
    formDataToSend.append('subcategory', formData.subcategory);
    formDataToSend.append('validity', formData.validity);
    formDataToSend.append('pcolor', formData.pcolor);
    formDataToSend.append('stone', formData.stone);
    formDataToSend.append('quantity', quantity);

    if (pimage) formDataToSend.append('pimage', pimage);
    if (topViewImage) formDataToSend.append('topViewImage', topViewImage);
    if (sideViewImage) formDataToSend.append('sideViewImage', sideViewImage);
    if (bottomViewImage) formDataToSend.append('bottomViewImage', bottomViewImage);

    try {
      const response = await fetch('https://sjpapi.prettywomen.in/API/v1/api/products', {
        method: 'POST',
        body: formDataToSend,
      });


      if (response.ok) {
        const result = await response.json();
     
        setProductList((prevList) => [result.product, ...prevList]);
        fetchProducts();
        setFormData({
          id: uuidv4(),
          pname: '',
          pimage: '',
          topViewImage: '',
          sideViewImage: '',
          bottomViewImage: '',
          stylecode: '',
          discount: '',
          pprice: '',
          category: '',
          subcategory: '',
          validity:'', // Default to today
          pcolor: '',
          quantity: '',
          stone: '',
        });
        setPImage(null);
        setTopViewImage(null);
        setSideViewImage(null);
        setBottomViewImage(null);
      } else {
        console.error('Error adding product!');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    alert('Product Added Successfully');
    toggleModal();
  };


  const handleSubmits = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('pname', formData.pname);
    formDataToSend.append('stylecode', formData.stylecode);
    formDataToSend.append('discount', formData.discount);
    formDataToSend.append('pprice', formData.pprice);
    formDataToSend.append('category', formData.category);
    formDataToSend.append('subcategory', formData.subcategory);
    // formDataToSend.append('validity', formData.validity);
    formDataToSend.append('pcolor', formData.pcolor);
    formDataToSend.append('stone', formData.stone);
    formDataToSend.append('quantity', quantity);

    if (pimage) formDataToSend.append('pimage', pimage);
    if (topViewImage) formDataToSend.append('topViewImage', topViewImage);
    if (sideViewImage) formDataToSend.append('sideViewImage', sideViewImage);
    if (bottomViewImage) formDataToSend.append('bottomViewImage', bottomViewImage);

    try {
      const response = await axios.put(`https://sjpapi.prettywomen.in/API/v1/api/products/${formData.id}`, formDataToSend);

      if (response.status === 200) {
        fetchProducts();
        setFormData({
          id: uuidv4(),
          pname: '',
          pimage: '',
          topViewImage: '',
          sideViewImage: '',
          bottomViewImage: '',
          stylecode: '',
          discount: '',
          pprice: '',
          category: '',
          subcategory: '',
          // validity: '',
          pcolor: '',
          quantity: '',
          stone: '',
        });
        // Refresh the product list
        setShowEditForm(false);
        // Hide the edit form
        alert('Product updated successfully!');
      } else {
        console.error('Error updating product!');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to update product.');
    }
  };


  const handleReset = () => {
    setFormData({
      id: uuidv4(),
      pname: '',
      pimage: '',
      topViewImage: '',
      sideViewImage: '',
      bottomViewImage: '',
      stylecode: '',
      discount: '',
      pprice: '',
      category: '',
      subcategory: '',
      validity: '', 
      pcolor: '',
      quantity: '',
      stone: '',
    });
  };



  const handleDelete = async (productId) => {
    try {
      const response = await fetch(`https://sjpapi.prettywomen.in/API/v1/api/product/${productId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      alert('Product deleted successfully');
      fetchProducts();
    } catch (error) {
      console.error('There was a problem with the delete request:', error);
      alert('Error deleting product');
    }
  };

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  // Calculate the indices of the first and last items for the current page
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = productList.slice(indexOfFirstProduct, indexOfLastProduct);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate pagination items
  const totalPages = Math.ceil(productList.length / itemsPerPage);
  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
        {i}
      </Pagination.Item>
    );
  }



  const [showEditForm, setShowEditForm] = useState(false);

  const handleEdit = async (productId) => {
    try {
      const response = await axios.get(`https://sjpapi.prettywomen.in/API/v1/get-product?id=${productId}`);
      const product = response.data[0];


      setFormData({
        id: product.id,
        pname: product.pname,
        pimage: product.pimage,
        topViewImage: product.topViewImage,
        sideViewImage: product.sideViewImage,
        bottomViewImage: product.bottomViewImage,
        stylecode: product.stylecode,
        discount: product.discount,
        pprice: product.pprice,
        category: product.category,
        subcategory: product.subcategory,
        // validity: product.validity,
        pcolor: product.pcolor,
        quantity: product.quantity,
        stone: product.stone,
      });
      setQuantity(product.quantity);
      setShowEditForm(true); // Show the edit form
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  const handleFileChanges = (event) => {
    const { id, files } = event.target;
    if (files.length > 0) {
      switch (id) {
        case 'pimage':
          setPImage(files[0]);
          break;
        case 'topViewImage':
          setTopViewImage(files[0]);
          break;
        case 'sideViewImage':
          setSideViewImage(files[0]);
          break;
        case 'bottomViewImage':
          setBottomViewImage(files[0]);
          break;
        default:
          break;
      }
    }
  };



  const [showEditModal, setShowEditModal] = useState(false);


  const handleEdits = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://sjpapi.prettywomen.in/API/v1/api/products/${formData.id}/validity`, { validity:(formData.validity) });
      setShowEditModal(false);
      fetchProducts();
      setFormData({validity: '' })
    } catch (error) {
      console.error('Error updating product:', error.response ? error.response.data : error.message);
    }
  };


  const handleClose = () => {
    setShowEditModal(false);
    setFormData({ id: '', validity: false }); // Ensure validity defaults to a boolean
  };

  const handleOpenEditModal = (product) => {
    setFormData({ id: product.id, validity: product.validity });
    setShowEditModal(true);
  };


  return (
    <>
      <div className="flex-row d-flex">
        <Sidebar />
        <div className="w-100">
          <Header />
          <div className="container" style={{ fontFamily: 'Arial,sans-serif', fontSize: '13px' }}>
            <div className="row" style={{ marginLeft: '20px' }}>
              <div className="card m-4" style={{ width: '30%' }}>
                <button className="btn btn-danger btn-sm m-2" onClick={toggleModal}>
                  Add Product
                </button>
              </div>
            </div>
            <h4 style={{ textAlign: 'center' }}>Products</h4>
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Product Image</th>
                    <th>Style Code</th>
                    <th>Discount</th>
                    <th>Price</th>
                    <th>Category</th>
                    <th>Subcategory</th>
                    <th>Validity</th>
                    <th>Color</th>
                    <th>Quantity</th>
                    <th>Stone</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(currentProducts) && currentProducts.map((product) => (
                    <tr key={product.id}>
                      <td>{product.pname}</td>
                      <td>
                        <img src={product.pimage} alt={product.pname} style={{ width: '50px', height: '50px' }} />
                      </td>
                      <td>{product.stylecode}</td>
                      <td>{product.discount}</td>
                      <td><FaRupeeSign /> {product.pprice}</td>
                      <td>{product.category}</td>
                      <td>{product.subcategory}</td>
                      <td>
                      {product.validity ? new Date(product.validity).toLocaleDateString() : "NULL"}
                        <CiEdit
                          style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }}
                          onClick={() => handleOpenEditModal(product)} // Open edit modal
                        />
                      </td>

                      <td>{product.pcolor}</td>
                      <td>{product.quantity}</td>
                      <td>{product.stone}</td>
                      <td>
                        <div className="d-flex">
                          <button className="btn btn-primary btn-sm me-2" onClick={() => handleEdit(product.id)}>
                            Edit
                          </button>

                          <button className="btn btn-danger btn-sm" onClick={() => handleDelete(product.id)}>
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>


            {showEditModal && (
              <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)', fontFamily: 'Arial,sans-serif', fontSize: '13px' }}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title">Edit Validity</h6>
                      <button type="button" className="btn-close" onClick={handleClose}></button>
                    </div>
                    <form onSubmit={handleEdits}>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="validity">Validity</label>
                          <input
                            type="date"
                            className="form-control"
                            id="validity"
                            value={formData.validity ? new Date(formData.validity).toISOString().split('T')[0] : ''}
                            min={new Date().toISOString().split('T')[0]}
                            onChange={handleChange}                           
                          />
                        </div>
                        <div className="gap-2 px-4">
                          <button type="submit" className="btn btn-danger btn-sm me-4">Update</button>
                          <button type="button" className="btn btn-primary btn-sm" onClick={handleClose}>Cancel</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}



            <Pagination className='justify-content-center'>{paginationItems}</Pagination>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)', fontFamily: 'Arial,sans-serif', fontSize: '13px' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title">Add Product</h6>
                <button type="button" className="btn-close" onClick={toggleModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="category">Category</label>
                    <select className="form-select" id="category" value={formData.category} onChange={handleCategoryChange} required>
                      <option value="">Select category</option>
                      {uniqueCategories.map((categoryName, index) => (
                        <option key={index} value={categoryName}>
                          {categoryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="subcategory">Sub Category</label>
                    <select className="form-select" id="subcategory" value={formData.subcategory} onChange={handleSubcategoryChange} required disabled={!formData.category} >
                      <option value="">Select subcategory</option>
                      {uniqueSubcategories.map((subcategoryName, index) => (
                        <option key={index} value={subcategoryName}>
                          {subcategoryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pname">Product Name</label>
                    <input type="text" className="form-control" id="pname" value={formData.pname} onChange={handleChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pimage">Product Image</label>
                    <input type="file" className="form-control" id="pimage" onChange={handleFileChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="topViewImage">Top View Image</label>
                    <input type="file" className="form-control" id="topViewImage" onChange={handleFileChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="sideViewImage">Side View Image</label>
                    <input type="file" className="form-control" id="sideViewImage" onChange={handleFileChange} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="bottomViewImage">Bottom View Image</label>
                    <input type="file" className="form-control" id="bottomViewImage" onChange={handleFileChange} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pprice">Product Price</label>
                    <input type="number" className="form-control" id="pprice" value={formData.pprice} onChange={handleChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="discount">Discount</label>
                    <input type="number" className="form-control" id="discount" value={formData.discount} onChange={handleChange} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="validity">Validity</label>
                    <input type="date" className="form-control" id="validity" value={formData.validity} min={new Date().toISOString().split('T')[0]} onChange={handleChange}  />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pcolor">Product Color</label>
                    <input type="text" className="form-control" id="pcolor" value={formData.pcolor} onChange={handleChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="stone">Stone</label>
                    <select className="form-control" id="stone" value={formData.stone} onChange={handleChange} required>
                      <option value="select stone">Select Stone</option>
                      <option value="none">None</option>
                      <option value="pearl">Pearl</option>
                      <option value="cz_stone">Cz Stone</option>
                      <option value="green">Green</option>
                      <option value="multi_colored">Multi Colored</option>
                      <option value="mother_of_pearl">Mother of Pearl</option>
                      <option value="black_stone">Black Stone</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="stylecode">STYLE CODE </label>
                    <textarea className="form-control" id="stylecode" value={formData.stylecode} onChange={handleChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="quantity">Quantity to Add</label>
                    <input type="number" className="form-control" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
                  </div>
                  <div className="gap-2 px-4">
                    <button type="submit" className="btn btn-danger btn-sm me-4">
                      Add
                    </button>
                    <button type="button" className="btn btn-primary btn-sm" onClick={handleReset}>
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}










      {showEditForm && (
        <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title">Edit Product</h6>
                <button type="button" className="btn-close" onClick={() => setShowEditForm(false)}></button>
              </div>
              <form onSubmit={handleSubmits}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="category">Category</label>
                    <select className="form-select" id="category" value={formData.category} onChange={handleCategoryChange} required>
                      <option value="">Select category</option>
                      {uniqueCategories.map((categoryName, index) => (
                        <option key={index} value={categoryName}>
                          {categoryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="subcategory">Sub Category</label>
                    <select className="form-select" id="subcategory" value={formData.subcategory} onChange={handleSubcategoryChange} required disabled={!formData.category} >
                      <option value="">Select subcategory</option>
                      <option value={formData.subcategory}>
                        {formData.subcategory}
                      </option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pname">Product Name</label>
                    <input type="text" className="form-control" id="pname" value={formData.pname} onChange={handleChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pimage">Product Image</label>
                    <input type="file" className="form-control" id="pimage" onChange={handleFileChanges} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="topViewImage">Top View Image</label>
                    <input type="file" className="form-control" id="topViewImage" onChange={handleFileChanges} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="sideViewImage">Side View Image</label>
                    <input type="file" className="form-control" id="sideViewImage" onChange={handleFileChanges} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="bottomViewImage">Bottom View Image</label>
                    <input type="file" className="form-control" id="bottomViewImage" onChange={handleFileChanges} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pprice">Product Price</label>
                    <input type="number" className="form-control" id="pprice" value={formData.pprice} onChange={handleChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="discount">Discount</label>
                    <input type="number" className="form-control" id="discount" value={formData.discount} onChange={handleChange} />
                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="validity">Validity</label>
                    <input type="date" className="form-control" id="validity" value={formData.validity} min={new Date().toISOString().split('T')[0]} onChange={handleChange} />
                  </div> */}
                  <div className="mb-3">
                    <label htmlFor="pcolor">Product Color</label>
                    <input type="text" className="form-control" id="pcolor" value={formData.pcolor} onChange={handleChange} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="stone">Stone</label>
                    <select className="form-control" id="stone" value={formData.stone} onChange={handleChange} required>
                      <option value="select stone">Select Stone</option>
                      <option value="none">None</option>
                      <option value="pearl">Pearl</option>
                      <option value="cz_stone">Cz Stone</option>
                      <option value="green">Green</option>
                      <option value="multi_colored">Multi Colored</option>
                      <option value="mother_of_pearl">Mother of Pearl</option>
                      <option value="black_stone">Black Stone</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="stylecode">STYLE CODE </label>
                    <textarea className="form-control" id="stylecode" value={formData.stylecode} onChange={handleChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="quantity">Quantity to Add</label>
                    <input type="number" className="form-control" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
                  </div>
                  <div className="gap-2 px-4">
                    <button type="submit" className="btn btn-danger btn-sm me-4">
                      Update
                    </button>
                    <button type="button" className="btn btn-primary btn-sm" onClick={handleReset}>
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StockAdd;




