import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LandingHeader from './LandingHeader';
import Footer from '../Footer/Footer';
import { FaRupeeSign } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const Necklaces = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter states
  const [availability, setAvailability] = useState('all');
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [pcolor, setColor] = useState('all');
  const [sortBy, setSortBy] = useState('relevance');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
        const allProducts = response.data;
        // Filter products for "Necklaces" subcategory
        const necklaces = allProducts.filter(product => product.subcategory === 'Necklaces');
        setProducts(necklaces);
      } catch (err) {
        setError('Failed to fetch products');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    let tempProducts = [...products];

    if (availability !== 'all') {
      tempProducts = tempProducts.filter(product => product.availability === availability);
    }

    tempProducts = tempProducts.filter(product => parseFloat(product.pprice) >= priceRange[0] && parseFloat(product.pprice) <= priceRange[1]);

    if (pcolor !== 'all') {
      tempProducts = tempProducts.filter(product => product.pcolor.toLowerCase() === pcolor.toLowerCase());
    }

    if (sortBy === 'price') {
      tempProducts = tempProducts.sort((a, b) => parseFloat(a.pprice) - parseFloat(b.pprice));
    }
    // Additional sorting logic for 'relevance' can be added here if needed

    setFilteredProducts(tempProducts);
  }, [availability, priceRange, pcolor, sortBy, products]);

  return (
    <>
    <div style={{fontFamily:'Arial, sans-serif',fontSize:'13px'}}>
      <LandingHeader />
      <div className='mt-5'>
        <div className='mt-5'>
          <h1 className='text-center'style={{color:' #d47bb1'}}><strong>NECKLACES</strong></h1>

          {/* Filters and Sorting UI */}
          <div className='container mt-5'>
            <div className='row mb-3'>
              {/* <div className='col-md-3'>
                <select onChange={(e) => setAvailability(e.target.value)} value={availability} className='form-select'>
                  <option value='all'>All Availability</option>
                  <option value='in-stock'>In Stock</option>
                  <option value='out-of-stock'>Out of Stock</option>
                </select>
              </div> */}
              <div className='col-md-4'>
                <select onChange={(e) => setPriceRange(JSON.parse(e.target.value))} value={JSON.stringify(priceRange)} className='form-select'>
                  <option value='[0,10000]'>All Prices</option>
                  <option value='[0,500]'>Up to 500</option>
                  <option value='[500,1000]'>500 - 1000</option>
                  <option value='[1000,1500]'>1000 - 1500</option>
                </select>
              </div>
              <div className='col-md-4'>
                <select onChange={(e) => setColor(e.target.value)} value={pcolor} className='form-select'>
                  <option value='all'>All Colors</option>
                  <option value='white'>White</option>
                  <option value='blue'>Blue</option>
                  <option value='green'>Green</option>
                  <option value='silver'>Silver</option>
                  <option value='gold'>Gold</option>
                </select>
              </div>
              <div className='col-md-4'>
                <select onChange={(e) => setSortBy(e.target.value)} value={sortBy} className='form-select'>
                  <option value='relevance'>Sort by Relevance</option>
                  <option value='price'>Sort by Price</option>
                </select>
              </div>
            </div>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className='container mt-5'>
              <div className='row'>
                {filteredProducts.length > 0 ? filteredProducts.map((product) => (
                  <div className='col-lg-3 col-md-4 col-6 mb-4' key={product.id}>
                    <Link to={`/product/${product.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <div className='shadow'>
                        <img
                          src={product.pimage}
                          alt={product.pname}
                          className='card-img-top poto'
                          style={{ height: '200px'}}
                        />
                        <div className='card-body'>
                          <h5 className='card-title'>{product.pname}</h5>
                          <p className='card-text'><FaRupeeSign /><strong>{product.pprice}</strong></p>                          
                        </div>
                      </div>
                    </Link>
                  </div>
                )) : (
                  <p>No necklaces available</p>
                )}
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
      </div>
    </>
  );
};

export default Necklaces;
