import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import { FiBox } from 'react-icons/fi';
import axios from 'axios';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AcDashboard = () => {
  const [orders, setOrders] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details');
        setOrders(response.data);
        processGraphData(response.data); // Process data for graphs
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  // Process orders data for graph representation
  const processGraphData = (data) => {
    const results = [];
    const groupedData = {};

    // Filter data based on selected date range
    const filteredData = data.filter(order => {
      const orderDate = new Date(order.transactionCreatedAt);
      return (!startDate || orderDate >= startDate) && (!endDate || orderDate <= endDate);
    });

    // Group filtered data by day, week, month, and year
    filteredData.forEach((order) => {
      const date = new Date(order.transactionCreatedAt);
      const day = date.toLocaleDateString();
      const month = date.toLocaleString('default', { month: 'long', year: 'numeric' });
      const year = date.getFullYear();

      // Daily
      groupedData[day] = groupedData[day] || { orders: 0, profit: 0, customers: new Set() };
      groupedData[day].orders += 1;
      groupedData[day].profit += order.amount / 100;
      groupedData[day].customers.add(order.userId);

      // Monthly
      groupedData[month] = groupedData[month] || { orders: 0, profit: 0, customers: new Set() };
      groupedData[month].orders += 1;
      groupedData[month].profit += order.amount / 100;
      groupedData[month].customers.add(order.userId);

      // Yearly
      groupedData[year] = groupedData[year] || { orders: 0, profit: 0, customers: new Set() };
      groupedData[year].orders += 1;
      groupedData[year].profit += order.amount / 100;
      groupedData[year].customers.add(order.userId);
    });

    for (const key in groupedData) {
      results.push({
        name: key,
        orders: groupedData[key].orders,
        profit: groupedData[key].profit,
        customers: groupedData[key].customers.size,
      });
    }
    setGraphData(results);
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div className='w-100'>
        <Header />
        <div className="container mt-4" style={{ fontFamily: 'Nexa, sans-serif' }}>
          <h3>Pretty Women Account Dashboard</h3>
          
          {/* Date Range Selection */}
          <div className="d-flex align-items-center mb-4">
            <label className="me-2">From:</label>
            <DatePicker selected={startDate} onChange={date => {
              setStartDate(date);
              processGraphData(orders); // Re-process on date change
            }} />
            <label className="me-2 ms-3">To:</label>
            <DatePicker selected={endDate} onChange={date => {
              setEndDate(date);
              processGraphData(orders); // Re-process on date change
            }} />
          </div>

          <div className="row mt-4">
            <DashboardCard title="ORDERS" count={orders.length} icon={<FiBox style={{ cursor: 'pointer' }} />} backgroundColor="#b42c2c" />
          </div>

          {/* Graphs Section */}
          <div className="mt-4">
            <h5>Orders, Profit, and Customers Over Time</h5>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="orders" stroke="#8884d8" />
                <Line type="monotone" dataKey="profit" stroke="#82ca9d" />
                <Line type="monotone" dataKey="customers" stroke="#ff7300" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardCard = ({ title, count, icon, backgroundColor }) => (
  <div className="col-md-3">
    <div className="card" style={{ backgroundColor }}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{title}</h5>
          {icon}
        </div>
        <h1 className="mt-3">{count}</h1>
      </div>
    </div>
  </div>
);

export default AcDashboard;
