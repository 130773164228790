
import React, { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { FaRupeeSign } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Footer from '../Footer/Footer';
import { addToCart } from '../../Redux/cartActions';
import { addToWishlist } from '../../Redux/wishlistSlice';
import './cart.css';
import LandingHeader from '../LandingPage/LandingHeader';

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null); // Initialize with null
  const [selectedImage, setSelectedImage] = useState('');
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const cartItems = useSelector(state => state.cart.cartItems);

  const handleAddToWishlist = () => {
    dispatch(addToWishlist({
      productId: id,
      quantity: 1,
      color: pcolor,
      name: pname,
      price: pprice,
      image: pimage,
      discount: discount
    }));
  };


  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-product', {
          params: { id } // Pass `id` as query parameter
        });

        if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
          const productData = response.data[0]; // Get the first product from the array
          setProduct(productData);
          setSelectedImage(productData.pimage);
        } else {
          console.error('Product not found or empty response.');
        }
      } catch (error) {
        console.error('Failed to fetch product details:', error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const [relatedProducts, setRelatedProducts] = useState([]);
  useEffect(() => {
    if (!product) return;

    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
        const allProducts = response.data;

        const filtered = allProducts.filter(p => p.subcategory === product.subcategory && p.id !== id).slice(0, 12);

        setRelatedProducts(filtered);
      } catch (err) {
        console.error('Failed to fetch products:', err);
      }
    };

    fetchProducts();
  }, [product]);


  const userId = localStorage.getItem('userId')

  // const handleAddToCart = async (productId, quantity, userId) => {
  //   try {
  //     const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/add-to-cart', { productId, quantity, userId });

  //     if (response.status === 200) {
  //       alert('Product added to cart successfully!');
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       // Server responded with a status other than 200
  //       switch (error.response.status) {
  //         case 400:
  //           alert('Error: Not enough stock available.');
  //           break;
  //         case 404:
  //           alert('Error: Product not found.');
  //           break;
  //         case 500:
  //           alert('Error: Server error. Please try again later.');
  //           break;
  //         default:
  //           alert('An unknown error occurred.');
  //       }
  //     } else {
  //       // No response from server
  //       alert('Network error. Please check your internet connection.');
  //     }
  //   }

  //   // Example of adding a product with quantity 1 (update quantity as needed)
  //   // dispatch(addToCart({ ...product, quantity }));
  // };

  const handleAddToCart = async (productId, quantity, userId) => {
    try {
      if (!userId) {
        // If user is not logged in, store the product and quantity in sessionStorage
        let cart = JSON.parse(sessionStorage.getItem('cart')) || [];
  
        // Find if the product already exists in the cart
        const existingProductIndex = cart.findIndex(item => item.productId === productId);
        
        if (existingProductIndex >= 0) {
          // If the product is already in the cart, update the quantity
          cart[existingProductIndex].quantity += quantity;
        } else {
          // Otherwise, add the product to the cart
          // Make sure you have `pname`, `pimage`, and `pprice` values for the product
          const product = {id, productId, quantity, pname, pimage, pprice };
          cart.push(product);
        }
  
        // Save the updated cart back to sessionStorage
        sessionStorage.setItem('cart', JSON.stringify(cart));
  
        // Optionally, update cart length in sessionStorage
        sessionStorage.setItem('cartlength', cart.length);
  
        alert('You are not logged in. Your cart will be saved temporarily.');
        return;
      }
  
      // If the user is logged in, make the API call to add the product to the cart
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/add-to-cart', { productId, quantity, userId });
  
      if (response.status === 200) {
        alert('Product added to cart successfully!');
      }
    } catch (error) {
      if (error.response) {
        // Handle errors based on response status
        switch (error.response.status) {
          case 400:
            alert('Error: Not enough stock available.');
            break;
          case 404:
            alert('Error: Product not found.');
            break;
          case 500:
            alert('Error: Server error. Please try again later.');
            break;
          default:
            alert('An unknown error occurred.');
        }
      } else {
        // No response from the server, likely a network error
        alert('Network error. Please check your internet connection.');
      }
    }
  
    // Example of adding a product to the cart with quantity 1 (update quantity as needed)
    dispatch(addToCart({ ...product, quantity }));
  };

  const calculateTimeRemaining = () => {
    if (!product || !product.validity) return 'Offer Closed';
    const endTime = new Date(product.validity).getTime();
    const now = new Date().getTime();
    const distance = endTime - now;

    if (distance <= 0) {
      return 'Offer Closed';
    } else {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }
  };

  const handleImageClick = (image) => {
    setIsZoomed(true);
    setZoomedImage(image);
  };

  const handleZoomedImageClick = () => {
    setIsZoomed(false);
    setZoomedImage(null);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <TailSpin color="#a91616" height={80} width={80} />
      </div>
    );
  }

  // Destructure product with default values to avoid undefined errors
  const {

    pname = '',
    pimage = '',
    topViewImage = '',
    sideViewImage = '',
    bottomViewImage = '',
    category = '',
    subcategory = '',
    pprice = 0,
    discount = 0,
    pcolor = '',
    stone = '',
    stylecode = ''
  } = product;





  return (
    <>
      <LandingHeader />
      <div className="container mt-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
        <div className="row">
          <div className="col-md-6">
            <div className="" >
              <img
                src={selectedImage}
                alt={pname}
                className="card-img-top imo"
                style={{ height: '400px' }}
                onClick={() => handleImageClick(selectedImage)}
              />
              <div className="m-2">
                <img
                  src={topViewImage}
                  style={{ height: '50px', width: '70px', cursor: 'pointer' }}
                  onClick={() => setSelectedImage(topViewImage)}
                />
                <img
                  src={sideViewImage}
                  style={{ height: '50px', width: '70px', cursor: 'pointer', marginLeft: '5px' }}
                  onClick={() => setSelectedImage(sideViewImage)}
                />
                <img
                  src={bottomViewImage}
                  style={{ height: '50px', width: '70px', cursor: 'pointer', marginLeft: '5px' }}
                  onClick={() => setSelectedImage(bottomViewImage)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div >
              <div>
                <h1 style={{ color: ' #d47bb1' }}><strong>{pname}</strong></h1>
                <p className="mb-2" style={{ fontSize: '20px' }}>
                  Price:{' '}
                  {discount ? (
                    <>
                      <span style={{ color: 'green', fontWeight: 'bold' }}>
                        <FaRupeeSign /> {Math.round(pprice - (pprice * discount) / 100)}
                      </span>{' '}
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: '#888',
                          fontSize: '0.85rem',
                          marginLeft: '5px',
                        }}
                      >
                        <FaRupeeSign /> {pprice}
                      </span>{' '}
                      <span style={{ color: '#d1235b', fontSize: '0.85rem', marginLeft: '5px' }}>
                        ({discount}% Off)
                      </span>
                    </>
                  ) : (
                    <span style={{ color: '#d1235b', fontWeight: 'bold' }}>
                      <FaRupeeSign /> {pprice}
                    </span>
                  )}
                </p>
                <p>Tax included. <a href="/shippingpolicy" className='text-black'>Shipping</a>  calculated at checkout.</p>
                <p className="mb-2"><strong>Category:</strong> {category}</p>
                <p className="mb-2"><strong>SubCategory:</strong> {subcategory}</p>

                <p className='mb-2 '><strong>Color:</strong>
                  <button
                    style={{
                      borderRadius: '30px',
                      backgroundColor: 'black',
                      color: 'white',
                      border: '1px solid black',
                      padding: '5px 30px', // Box shape
                      fontSize: '14px',
                      textTransform: 'uppercase', // Uppercase text
                      cursor: 'pointer',
                      marginRight: '0.5rem', // Space between buttons
                      display: 'inline-block'
                    }}
                  >
                    {pcolor}
                  </button></p>
                <p><strong>Time Remaining:</strong> {calculateTimeRemaining()}</p>
                <hr />
                <div style={{ display: 'inline-block', marginBottom: '1rem' }}>
                  <Link to="/wishlist">
                    <button
                      style={{
                        border: '1px solid black',
                        padding: '10px 20px', // Box shape
                        fontSize: '14px',
                        textTransform: 'uppercase', // Uppercase text
                        cursor: 'pointer',
                        marginRight: '0.5rem', // Space between buttons
                        display: 'inline-block'
                      }}
                      onClick={handleAddToWishlist}
                    >
                      Add to Wishlist
                    </button>
                  </Link>
                  <Link to="/cart">
                    <button
                      style={{
                        border: '1px solid black',
                        padding: '10px 20px', // Box shape
                        fontSize: '14px',
                        textTransform: 'uppercase', // Uppercase text
                        cursor: 'pointer',
                        display: 'inline-block'
                      }}
                      onClick={() => handleAddToCart(id, 1, userId)}
                    >
                      Add to Cart
                    </button>
                  </Link>
                </div>
                <p>{pcolor} {pname} {subcategory}</p>
                <p>Stone:{stone}</p>
                <p><strong>Style Code:</strong> <button
                  style={{
                    borderRadius: '30px',
                    backgroundColor: 'black',
                    color: 'white',
                    border: '1px solid black',
                    padding: '5px 30px', // Box shape
                    fontSize: '14px',
                    textTransform: 'uppercase', // Uppercase text
                    cursor: 'pointer',
                    marginRight: '0.5rem', // Space between buttons
                    display: 'inline-block'
                  }}
                >
                  {stylecode}
                </button></p>
              </div>
            </div>
          </div>
        </div>
        {relatedProducts.length > 0 && (
          <div className="related-products mt-5">
            <h1 style={{ color: ' #d47bb1' }}><strong>You May Also Like</strong></h1>
            <div className="row mt-5">
              {relatedProducts.map((prod) => (
                <div key={prod.id} className="col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
                  <Link to={`/detail/${prod.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className="shadow">
                      <img
                        src={prod.pimage}
                        alt={prod.pname}
                        className='card-img-top poto'
                        style={{ height: '250px' }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{prod.pname}</h5>
                        <p className="card-text">
                          <FaRupeeSign /> {Math.round(prod.pprice - (prod.pprice * prod.discount) / 100)}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>

          </div>
        )}

      </div>
      {isZoomed && (
        <div className="zoomedImageContainer" onClick={handleZoomedImageClick}>
          <img src={zoomedImage} alt={pname} className="zoomedImage" style={{ height: '90%' }} />
        </div>
      )}
      <Footer />
    </>
  );
};

export default ProductDetails;
