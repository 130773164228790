import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Image } from 'react-bootstrap';
import Logo1 from '../Pages/Logo1.png';
import ReCAPTCHA from "react-google-recaptcha";
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from '../Footer/Footer';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors

    try {
      const response = await fetch('https://sjpapi.prettywomen.in/API/v1/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setResetToken(data.resetToken); // Assuming your backend returns resetToken upon successful request
        setResetPasswordMode(true);
      } else {
        setError(data.error || 'Failed to send password reset email');
      }
    } catch (error) {
      setError('Failed to send password reset email');
    }
  };

  return (
    <>
      <LandingHeader />
      <div className="d-flex justify-content-center align-items-center">
        <Row className="justify-content-center" style={{ fontFamily: 'Nexa, sans-serif' }}>
          <Col className="p-4 p-md-5 rounded bg-light">
            <div className="d-flex align-items-center mb-3">
              <h6 className="mb-0" style={{ fontSize: '40px' }}>Reset Your Password</h6>
            </div>

            <Form onSubmit={handleSubmit}>
              {error && <Alert variant="danger">{error}</Alert>}
              {resetPasswordMode && <Alert variant="success">Check your email for a password reset link.</Alert>}

              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <div className='mb-3'>
                  <input
                    type='email'
                    placeholder='Enter email'
                    style={{ border: '1px solid black', padding: '10px 120px'}}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

              </Form.Group>
              <ReCAPTCHA className='mb-3'
              sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU"             
           />
              <Button type="submit" variant="dark" className="w-100">
                Reset Password
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
