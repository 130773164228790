import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LandingHeader from './LandingHeader';
import Footer from '../Footer/Footer';
import { FaRupeeSign } from 'react-icons/fa6';
import { Link, useLocation } from 'react-router-dom';


const Searchresults = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query') || '';

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
        setProducts(response.data);
      } catch (err) {
        setError('Failed to fetch products');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    setSearch(query);
  }, [query]);

  const filteredProducts = products.filter(product => {
    const lowerSearch = search.toLowerCase();
    return (
      (product.pname || '').toLowerCase().includes(lowerSearch) ||
      (product.category || '').toLowerCase().includes(lowerSearch) ||
      (product.subcategory || '').toLowerCase().includes(lowerSearch) ||
      (product.discount || '').toString().toLowerCase().includes(lowerSearch) ||
      (product.pcolor || '').toLowerCase().includes(lowerSearch)
    );
  });

  const updateSuggestions = (inputValue) => {
    if (inputValue) {
      const filteredSuggestions = products
        .map(product => product.pname)
        .filter(suggestion =>
          suggestion.toLowerCase().includes(inputValue.toLowerCase())
        );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    updateSuggestions(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearch(suggestion);
    setSuggestions([]);
    setShowSuggestions(false);
  };

  return (
    <>
      <LandingHeader />
      <div className='mt-5' style={{fontFamily:'Arial, sans-serif',fontSize:'13px'}}>
        <h1 className='text-center mb-4' style={{color:' #d47bb1'}}><strong>SEARCH RESULTS</strong></h1>
        <div className="d-flex justify-content-center">
          <div style={{ position: 'relative', width: '100%', maxWidth: '600px' }}>
            <input
              placeholder="Search"
              style={{ border: '1.5px solid gray', padding: '10px 15px' }}
              aria-label="Search"
              value={search}
              onChange={handleInputChange}
              onFocus={() => setShowSuggestions(true)}
              onBlur={() => setTimeout(() => setShowSuggestions(false), 100)}
              className='form-control'
            />
            {showSuggestions && suggestions.length > 0 && (
              <ul className='suggestions-list' style={{ position: 'absolute', top: '100%', left: 0, width: '100%', border: '1px solid #ccc', backgroundColor: '#fff', zIndex: 1000, padding: 0, margin: 0, listStyleType: 'none' }}>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    style={{ padding: '10px', cursor: 'pointer' }}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {loading ? (
          <p className='text-center mt-4'>Loading...</p>
        ) : error ? (
          <p className='text-center mt-4'>{error}</p>
        ) : (
          <div className='container mt-5'>
            <div className='row'>
              {filteredProducts.length > 0 ? filteredProducts.map((product) => (
                <div className='col-lg-3 col-md-4 col-6 mb-4' key={product.id}>
                  <Link to={`/product/${product.id}`} className='text-decoration-none text-dark'>
                    <div className='card shadow'>
                      <img
                        src={product.pimage}
                        alt={product.pname}
                        className='card-img-top poto'
                        style={{ height: '200px' }}
                      />
                      <div className='card-body'>
                        <h5 className='card-title'>{product.pname}</h5>
                        <p className='card-text'><FaRupeeSign /><strong>{product.pprice}</strong></p>
                      </div>
                    </div>
                  </Link>
                </div>
              )) : (
                <p className='text-center col-12'>No products available</p>
              )}
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default Searchresults;
