import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import { Modal, Button } from 'react-bootstrap';

function User() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    password: '',
    confirmPassword: '',
    userType: '',
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false); 
  const [editIndex, setEditIndex] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [usersData,setUsersData]=useState([])

  
  
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://sjpapi.prettywomen.in/API/v1/api/usersd');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUsersData(data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    useEffect(() => {
    fetchUsers();
     }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
  
    if (!formData.name) {newErrors.name = 'Name is required';}
    if (!formData.mobile) {newErrors.mobile = 'Mobile number is required';}
    if (!formData.email) {newErrors.email = 'Email is required';}
    else if (!/\S+@\S+\.\S+/.test(formData.email)) {newErrors.email = 'Email is invalid';}
    if (!formData.password) {newErrors.password = 'Password is required'; }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      try {
        const method = editIndex !== null ? 'PUT' : 'POST';
        const url = editIndex !== null
          ? `https://sjpapi.prettywomen.in/API/v1/api/users/${usersData[editIndex].id}`
          : 'https://sjpapi.prettywomen.in/API/v1/api/users';
  
        const response = await fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to add or update user');
        }
  
        setFormData({
          name: '',
          mobile: '',
          email: '',
          password: '',
          confirmPassword: '',
          userType: '',
        });
        setShowModal(false);
        alert('User successfully ' + (editIndex !== null ? 'updated' : 'added') + '!');
  
        await fetchUsers();
      } catch (error) {
        console.error('Failed to add or update user:', error);
        alert('Failed to add or update user.');
      }
    }
  };
  

  const handleEdit = (index) => {
    setEditIndex(index);
    const user = usersData[index];
    setFormData({
      name: user.name,
      mobile: user.mobile,
      email: user.email,
      password: user.password,
      confirmPassword: user.password, // Pre-fill confirmPassword with existing password
      userType: user.user_type,
    });
    setShowModal(true); // Show the modal for editing
  };
 
  const handleDelete = async (index) => {
    const userId = usersData[index].id; 
  
    if (window.confirm('Are you sure you want to delete this user?')) {
      const updatedUsers = usersData.filter((_, i) => i !== index);
      setUsersData(updatedUsers);
  
      try {
        const response = await fetch(`https://sjpapi.prettywomen.in/API/v1/api/users/${userId}`, {
          method: 'DELETE',
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete user');
        }
      } catch (error) {
        console.error('Failed to delete user:', error);         
      }
    }
  };
  

  const handleView = (index) => {
    setSelectedUser(usersData[index]); 
    setShowViewModal(true); 
  };

 

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="w-100">
        <Header />
        <div className="container mt-5" style={{ fontFamily: 'Arial, sans-serif',fontSize:'13px'}}>
          <Button variant="danger btn-sm" onClick={() => setShowModal(true)}>
            User Management
          </Button>
        </div>
        <div className="container mt-5" style={{ fontFamily: 'Arial, sans-serif',fontSize:'13px' }}>
          <h6>Users List</h6>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>User Type</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {usersData.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center">No users available</td>
                </tr>
              ) : (
                usersData.map((user, index) => (
                  <tr key={index}>
                    <td>{user.name}</td>
                    <td>{user.mobile}</td>
                    <td>{user.user_type}</td>
                    <td>{user.email}</td>
                    <td>
                      {/* <Button variant="warning btn-sm" onClick={() => handleEdit(index)}>
                        Edit
                      </Button> */}
                      <Button variant="info btn-sm ms-2" onClick={() => handleView(index)}>
                        View
                      </Button>
                      <Button variant="danger btn-sm ms-2" onClick={() => handleDelete(index)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for User Registration Form */}
      <Modal show={showModal} onHide={() => setShowModal(false)} style={{ fontFamily: 'Arial, sans-serif',fontSize:'13px' }}>
        <Modal.Header closeButton>
          <Modal.Title >{editIndex !== null ? 'Edit User' : 'User Registration Form'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
              />
              {errors.name && <div className="invalid-feedback">{errors.name}</div>}
            </div>

            <div className="form-group">
              <label>Mobile Number:</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
              />
              {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
            </div>

            <div className="form-group">
              <label>User Type:</label>
              <select
                name="userType"
                value={formData.userType}
                onChange={handleInputChange}
                className={`form-control ${errors.userType ? 'is-invalid' : ''}`}
              >
                <option value="">Select an option</option>
                <option value="StoctAdd">Stock</option>
                <option value="logistics">Logistics</option>
                <option value="account">Account</option>
                <option value="admin">Admin</option>
              </select>
              {errors.userType && (
                <div className="invalid-feedback">{errors.userType}</div>
              )}
            </div>

            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>

            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
              />
              {errors.password && <div className="invalid-feedback">{errors.password}</div>}
            </div>

            <div className="form-group">
              <label>Confirm Password:</label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
              />
              {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
            </div>
            <hr />
            <button type="submit" className="btn btn-primary btn-sm">
              {editIndex !== null ? 'Update' : 'Submit'}
            </button>
          </form>
        </Modal.Body>
      </Modal>

      {/* Modal for Viewing User Details */}
      <Modal show={showViewModal} onHide={() => setShowViewModal(false)} style={{ fontFamily: 'Arial, sans-serif',fontSize:'13px' }}>
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser  ? (
            <div>
              <p><strong>Name:</strong> {selectedUser.name}</p>
              <p><strong>Mobile:</strong> {selectedUser.mobile}</p>
              <p><strong>User Type:</strong> {selectedUser.user_type}</p>
              <p><strong>Email:</strong> {selectedUser.email}</p>
              <p><strong>Password:</strong> {selectedUser.password}</p> {/* Show passwords in plain text */}
            </div>
          ) : (
            <p>No user selected</p>
          )}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default User;
