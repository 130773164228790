import { ADD_TO_CART, CHANGE_QUANTITY, REMOVE_FROM_CART, CLEAR_CART } from './cartActionTypes';

const initialState = {
  cartItems: JSON.parse(localStorage.getItem('cartItems')) || [],
  totalQuantity: JSON.parse(localStorage.getItem('totalQuantity')) || 0,
};

const calculateTotalQuantity = (items) => {
  return items.reduce((total, item) => total + item.quantity, 0);
};

const cartReducer = (state = initialState, action) => {
  let updatedCartItems = [...state.cartItems];
  let updatedTotalQuantity;

  switch (action.type) {
    case ADD_TO_CART:
      const existingCartItemIndex = state.cartItems.findIndex(item => item.productId === action.payload.productId);
      if (existingCartItemIndex !== -1) {
        updatedCartItems[existingCartItemIndex].quantity += action.payload.quantity;
      } else {
        updatedCartItems.push(action.payload);
      }
      break;

    case CHANGE_QUANTITY:
      updatedCartItems = updatedCartItems.map(item =>
        item.productId === action.payload.productId
          ? { ...item, quantity: action.payload.quantity }
          : item
      );
      break;
      

    case REMOVE_FROM_CART:
      updatedCartItems = updatedCartItems.filter(item => item.productId !== action.payload);
      break;

    case CLEAR_CART:
      updatedCartItems = [];
      break;

    default:
      return state;
  }

  updatedTotalQuantity = calculateTotalQuantity(updatedCartItems);
  localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  localStorage.setItem('totalQuantity', JSON.stringify(updatedTotalQuantity));

  return {
    ...state,
    cartItems: updatedCartItems,
    totalQuantity: updatedTotalQuantity,
  };
};

export default cartReducer;
