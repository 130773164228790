
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import Header from '../Head/Header';
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios';

const StockChecking = () => {
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [subCategoryQuantities, setSubCategoryQuantities] = useState({});

  useEffect(() => {
    // Fetch categories from API
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/ap/subcategories');
        const uniqueCategories = response.data.filter((value, index, self) =>
          index === self.findIndex((t) => t.name === value.name)
        );
        setCategories(uniqueCategories.map(cat => cat.name));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    // Fetch products data
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
        const productsData = response.data;

        setProducts(productsData);

        // Calculate quantities for each subcategory based on selected category
        const calculateSubCategoryQuantities = () => {
          const quantities = {};

          productsData.forEach((product) => {
            if (product.category === category) {
              if (!quantities[product.subcategory]) {
                quantities[product.subcategory] = {
                  purchases: 0,
                  sales: 0,
                };
              }
              quantities[product.subcategory].purchases += parseInt(product.quantity, 10) || 0;
              quantities[product.subcategory].sales += parseInt(product.sales, 10) || 0;
            }
          });

          setSubCategoryQuantities(quantities);
        };

        if (category) {
          calculateSubCategoryQuantities();
        } else {
          setSubCategoryQuantities({});
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [category]);

  return (
    <div className="d-flex">
      <Sidebar />
      <Container fluid className="w-100">
        <Header />
        <Container style={{padding:'20px',fontFamily:"Arial, sans-serif",fontSize:'13px'}}>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {categories.map(cat => (
                    <option key={cat} value={cat}>{cat}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Subcategory</th>
                <th>Purchases</th>
                <th>Sales</th>
                <th>Available Stock</th>
              </tr>
            </thead>
            <tbody>
              {category ? (
                Object.keys(subCategoryQuantities).length > 0 ? (
                  Object.entries(subCategoryQuantities).map(([subcat, { purchases, sales }]) => (
                    <tr key={subcat}>
                      <td>{subcat}</td>
                      <td>{purchases}</td>
                      <td>{sales}</td>
                      <td>{purchases - sales}</td> {/* Display available stock */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No products available for this category</td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="4">Select a category to view products</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      </Container>
    </div>
  );
};

export default StockChecking;
