// import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import CartItem from './cartItem';
// import Footer from '../Footer/Footer';
// import { Link } from 'react-router-dom';
// import LandingHeader from '../LandingPage/LandingHeader';

// const CartTab = () => {
//   const [cartItems, setCartItems] = useState([]);
//   const [alert, setAlert] = useState({ message: '', type: '' });
//   const dispatch = useDispatch();
//   const userId = localStorage.getItem('userId');

// //https://sjpapi.prettywomen.in/API/v1
// //http://localhost:3008

//   // const fetchCartItems = async () => {
//   //   try {
//   //     const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/cart', { userId });
//   //     setCartItems(response.data);
//   //     localStorage.setItem('cartlength', response.data.length);
//   //   } catch (err) {
//   //     setAlert({ message: 'Failed to fetch cart items. Please try again later.', type: 'error' });
//   //     console.error('Failed to fetch cart items:', err);
//   //   }
//   // };

//   // useEffect(() => {
//   //   fetchCartItems();
//   // }, []);

//   const fetchCartItems = async () => {
//     try {
//       // Check if userId exists, and fetch from API or sessionStorage accordingly
//       if (userId) {
//         // Fetch cart from the API if user is logged in
//         const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/cart', { userId });
//         setCartItems(response.data); // Set the state with the fetched cart items
//         sessionStorage.setItem('cartlength', response.data.length); // Store cart length in sessionStorage
//         sessionStorage.setItem('cart', JSON.stringify(response.data)); // Store full cart data in sessionStorage
//       } else {
//         // If no userId, fetch cart from sessionStorage
//         const cartFromSession = JSON.parse(sessionStorage.getItem('cart')) || []; // Default to empty array if no cart found
//         setCartItems(cartFromSession); // Set the state with cart from sessionStorage
//         sessionStorage.setItem('cartlength', cartFromSession.length); // Update cart length in sessionStorage
//       }
//     } catch (err) {
//       setAlert({ message: 'Failed to fetch cart items. Please try again later.', type: 'error' });
//       console.error('Failed to fetch cart items:', err);
//     }
//   };
  

  
// useEffect(() => {
//   fetchCartItems();
// }, []);

//   // const handleRemoveItem = (id) => {
//   //   setCartItems((prevItems) => prevItems.filter(item => item.id !== id));
//   // };

//   const handleRemoveItem = (id) => {
//     const updatedCart = cartItems.filter(item => item.id !== id);
//     setCartItems(updatedCart); // Update state
//     sessionStorage.setItem('cart', JSON.stringify(updatedCart)); // Update sessionStorage
//     sessionStorage.setItem('cartlength', updatedCart.length); // Update cart length in sessionStorage
//   };
  

//   const calculateTotalPrice = (items) => {
//     return items.reduce((total, item) => {
//       return total + (item.pprice - (item.pprice * item.discount) / 100) * item.quantity;
//     }, 0);
//   };
  
//   const totalPrice = calculateTotalPrice(cartItems);
//   // const totalPrice = cartItems.reduce((total, item) => {
//   //   return total + (item.pprice - (item.pprice * item.discount) / 100) * item.quantity;
//   // }, 0);

//   return (
//     <div>
//       <LandingHeader />
//       <div className="container mt-5">
//         <h1 className='text-center mt-5' style={{ color: '#d47bb1' }}><strong>SHOPPING CART</strong></h1>
//         <div className='container-fluid mt-5'>
//           <div className='row bg-light justify-content-center'>
//             {cartItems.length > 0 ? (
//               cartItems.map((item) => (
//                 <CartItem key={item.id} data={item} onRemove={handleRemoveItem} />
//               ))
//             ) : (
//               <div className='text-center'>Your cart is empty</div>
//             )}
//           </div>
//         </div>
//         <div className='container text-center mt-5'>
//           <h6>Total Price: Rs. {Math.round(totalPrice)}</h6>
//           {cartItems.length > 0 ? (
//             <Link to="/checkoutpage">
//               <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>
//                 CHECKOUT
//               </button>
//             </Link>
//           ) : (
//             <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%', opacity: 0.5, cursor: 'not-allowed' }} disabled>
//               CHECKOUT
//             </button>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default CartTab;



import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import CartItem from './cartItem';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import LandingHeader from '../LandingPage/LandingHeader';

const CartTab = () => {
  const [cartItems, setCartItems] = useState([]);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');

  // Function to calculate total price of cart items
  const calculateTotalPrice = (items) => {
    return items.reduce((total, item) => {
      // Check if item properties exist and are valid numbers
      const price = item.pprice && !isNaN(item.pprice) ? item.pprice : 0;
      const discount = item.discount && !isNaN(item.discount) ? item.discount : 0;
      const quantity = item.quantity && !isNaN(item.quantity) ? item.quantity : 0;
  
      // Calculate price
      const itemTotal = (price - (price * discount) / 100) * quantity;
  
      return total + itemTotal;
    }, 0);  
  };
  
  // Function to fetch cart items from API or sessionStorage
  const fetchCartItems = async () => {
    try {
      if (userId) {
        // Fetch cart from the API if user is logged in
        const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/cart', { userId });
        setCartItems(response.data); // Set the state with the fetched cart items
        sessionStorage.setItem('cartlength', response.data.length); // Store cart length in sessionStorage
        sessionStorage.setItem('cart', JSON.stringify(response.data)); // Store full cart data in sessionStorage
      } else {
        // If no userId, fetch cart from sessionStorage
        const cartFromSession = JSON.parse(sessionStorage.getItem('cart')) || []; // Default to empty array if no cart found
        setCartItems(cartFromSession); // Set the state with cart from sessionStorage
        sessionStorage.setItem('cartlength', cartFromSession.length); // Update cart length in sessionStorage
      }
    } catch (err) {
      setAlert({ message: 'Failed to fetch cart items. Please try again later.', type: 'error' });
      console.error('Failed to fetch cart items:', err);
    }
  };

  // Handle removing an item (update both state and sessionStorage)
  const handleRemoveItem = (id) => {
    const updatedCart = cartItems.filter(item => item.id !== id);
    setCartItems(updatedCart); // Update state
    sessionStorage.setItem('cart', JSON.stringify(updatedCart)); // Update sessionStorage
    sessionStorage.setItem('cartlength', updatedCart.length); // Update cart length in sessionStorage
  };
  useEffect(() => {
    fetchCartItems(); // Fetch cart items when the component mounts
  }, []);

  // Total price calculation
  const totalPrice = calculateTotalPrice(cartItems);

  return (
    <div>
      <LandingHeader />
      <div className="container mt-5">
        <h1 className='text-center mt-5' style={{ color: '#d47bb1' }}><strong>SHOPPING CART</strong></h1>
        <div className='container-fluid mt-5'>
          <div className='row bg-light justify-content-center'>
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <CartItem key={item.id} data={item} onRemove={handleRemoveItem} />
              ))
            ) : (
              <div className='text-center'>Your cart is empty</div>
            )}
          </div>
        </div>
        <div className='container text-center mt-5'>
          <h6>Total Price: Rs. {Math.round(totalPrice)}</h6>
          {cartItems.length > 0 ? (
            <Link to="/checkoutpage">
              <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>
                CHECKOUT
              </button>
            </Link>
          ) : (
            <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%', opacity: 0.5, cursor: 'not-allowed' }} disabled>
              CHECKOUT
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CartTab;
