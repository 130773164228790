import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './Components/Sidebar/Sidebar';
import Dashboard from './Components/Pages/Dashboard';
import StockAdd from './Components/Pages/StockAdd';
import User from './Components/Pages/User';
import Account from './Components/Pages/Account.js';
import Customers from './Components/Pages/Customers';
import Logistics from './Components/Pages/Logistics';
import AdminLogin from './Components/AdminLogin/Adminlogin';
import StockChecking from './Components/Pages/StockChecking';
import ProductMaster from './Components/Pages/ProductMaster';
import 'bootstrap/dist/css/bootstrap.min.css';
import CartTab from './Components/Cart/cartTab';
import Login from './Components/Auth/Login';
import Signup from './Components/Auth/Signup.js';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import LandingPage from './Components/LandingPage/LandingPage';
import About from './Components/LandingPage/About';
import Wishlist from './Components/Wishlist/Wishlist';
import CheckoutPage from './Components/Checkout/CheckoutPage';
import PaymentPage from './Components/Checkout/PaymentPage.js';
import EmailVerification from './Components/Auth/emailverification.js';
import ResetPassword from './Components/Auth/ResetPassword';
import ProductDetails from './Components/Cart/ProductDetails';
import Detail from './Components/Cart/Detail';
import AcDashboard from './Components/Pages/AcDashboard.js';
import LoDashboard from './Components/Pages/LoDashboard.js';
import PaymentStatus from './Components/Checkout/PaymentStatus.js';
import TermsConditions from './Components/Footer/TermsConditions.js';
import PrivacyPolicy from './Components/Footer/PrivacyPolicy.js';
import ShippingPolicy from './Components/Footer/ShippingPolicy.js';
import Return from './Components/Footer/Return.js';
import Refund from './Components/Footer/Refund.js';
import Trending from './Components/LandingPage/Trending.js';
import Bracelets from './Components/LandingPage/Bracelets.js';
import Earrings from './Components/LandingPage/Earrings.js';
import Necklaces from './Components/LandingPage/Necklaces.js';
import Rings from './Components/LandingPage/Rings.js';
import Product from './Components/LandingPage/Product.js';
import Searchresults from './Components/LandingPage/Searchresults.js';
import Guestlogin from './Components/Auth/Guestlogin.js';
import MyAccount from './Components/LandingPage/Myaccount.js';
import Offerzone from './Components/Pages/Offerzone.js';
import Faq from './Components/Footer/Faq.js';




const App = () => {

  return (
    <Router>
      <Routes>
        <Route path='/adminlogin' element={<AdminLogin />} />
        <Route path="/sidebar" element={<Sidebar />} />
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/offerzone" element={<ProtectedRoute element={<Offerzone />} />} />
        <Route path="/stockAdd" element={<ProtectedRoute element={<StockAdd />} />} />
        <Route path='/user' element={<ProtectedRoute element={<User />} />} />
        <Route path='/account' element={<ProtectedRoute element={<Account />} />} />
        <Route path='/customers' element={<ProtectedRoute element={<Customers />} />} />
        <Route path='/logistics' element={<ProtectedRoute element={<Logistics />} />} />
        <Route path='/stockChecking' element={<ProtectedRoute element={<StockChecking />} />} />
        <Route path='/productMaster' element={<ProtectedRoute element={<ProductMaster />} />} />
        <Route path='/otp-verification' element={<EmailVerification />} />
        <Route path='/reset-password/:token' element={<ResetPassword />} />
        <Route path='/Myaccount' element={<MyAccount />} />
        <Route path='/acdashboard' element={<AcDashboard />} />
        <Route path='/lodashboard' element={<LoDashboard />} />
        <Route path='/pretty/pg/v1/payment-status/:trid/:mt/:userId/:pId/:shipCharge' element={<PaymentStatus />} />
        <Route path='/detail/:id' element={<Detail />} />
        <Route path='/guestlogin' element={<Guestlogin />} />
        <Route path='/' element={<LandingPage />} />
        <Route path='/about' element={<About />} />
        <Route path='/trend' element={<Trending />} />
        <Route path='/brace' element={<Bracelets />} />
        <Route path='/earrings' element={<Earrings />} />
        <Route path='/necklaces' element={<Necklaces />} />
        <Route path='/rings' element={<Rings />} />
        <Route path='/product' element={<Product />} />
        <Route path='/searchresults' element={<Searchresults />} />
        <Route path='/login' element={<Login />} />
       
        <Route path='/productdetails/:id' element={<ProductDetails />} />
        <Route path='/product/:id' element={<ProductDetails />} />
        <Route path='/termsconditions' element={<TermsConditions />} />
        <Route path="/signup" element={<Signup />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path='/shippingpolicy' element={<ShippingPolicy />} />
        <Route path='/return' element={<Return />} />
        <Route path='/faq' element={<Faq/>} />
        <Route path='/refund' element={<Refund />} />
        <Route path="/cart"  element={<CartTab />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path="/checkoutpage" element={<ProtectedRoute element={<CheckoutPage />} />} />
        <Route path="/paymentpage" element={<ProtectedRoute element={<PaymentPage />} />} /> 
              
      </Routes>
    </Router>
  );
};

export default App;
