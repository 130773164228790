import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import { Table, Container, Pagination } from 'react-bootstrap';
import axios from 'axios';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = 30;

  // Fetch customer data when the component mounts
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/customer'); // Adjust the URL as necessary
        setCustomers(response.data);
      } catch (err) {
        setError('Failed to fetch customer data');
      }
    };

    fetchCustomers();
  }, []);

  if (error) return <div>{error}</div>;

  // Logic for pagination
  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = customers.slice(indexOfFirstCustomer, indexOfLastCustomer);
  
  const pageNumbers = Math.ceil(customers.length / customersPerPage);

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="w-100">
        <Header />
        <Container className="my-4">
          <h5>Customers Details</h5>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Username</th>
                <th>Mobile Number</th>
                <th>Email ID</th>
                <th>Billing Address</th>
              </tr>
            </thead>
            <tbody>
              {currentCustomers.length > 0 ? (
                currentCustomers.map((customer, index) => (
                  <tr key={index}>
                    <td>{customer.username}</td>
                    <td>{customer.mobile}</td>
                    <td>{customer.email}</td>
                    <td>
                      {customer.billingAddress ? `${customer.billingAddress.address}, ${customer.billingAddress.city}, ${customer.billingAddress.state}, ${customer.billingAddress.zip}` : 'N/A'}
                    </td>                 
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No customer data available</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination className='justify-content-center'>
            <Pagination.Prev
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            />
            {Array.from({ length: pageNumbers }, (_, i) => (
              <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => setCurrentPage(i + 1)}>
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageNumbers))}
              disabled={currentPage === pageNumbers}
            />
          </Pagination>
        </Container>
      </div>
    </div>
  );
};

export default Customers;
