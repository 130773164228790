import React from 'react';
import LandingHeader from './LandingHeader';
import Footer from '../Footer/Footer';
import a1 from '../images/a1.jpeg';
import a2 from '../images/a2.jpeg';
import a3 from '../images/a3.jpeg';
import { Container } from 'react-bootstrap';

const About = () => {
  return (
    <>
    <div style={{fontFamily:'Arial, sans-serif',fontSize:'13px'}}>
      <LandingHeader />
      <Container className="my-4 text-center">
        <div className="container text-center my-5">
          <h2 className="mb-5 text-center" style={{ fontSize: '50px', color: '#d47bb1' }}><strong>ABOUT</strong></h2>
          <div className="row justify-content-center mb-4">
            <div className="col-12 col-md-4 mb-3">
              <img src={a1} alt="a1" className="img-fluid " />
            </div>
            <div className="col-12 col-md-4 mb-3">
              <img src={a2} alt="a2" className="img-fluid " />
            </div>
            <div className="col-12 col-md-4 mb-3">
              <img src={a3} alt="a3" className="img-fluid " />
            </div>
          </div>
        </div>

        <div className="container mb-5">
          <p >
            <strong>Sweety Jewels Private Limited</strong> is focused on creating fashion and contemporary jewellery & accessories.<br/>
            We are pioneers of fashion jewellery in India and among the first Indian business houses to launch Shop-in-Shop modules across all formats.<br/>
            The brand “Pretty Women” Basic, Alluring, and Affordable was launched in 2004. We are a designer jewellery lifestyle brand focused on luxury, glamour, and beauty within.<br/>
            Our collections draw inspiration from art, culture, travel, love, society, and old-world glamour, all while emphasizing variety.
          </p>
        </div>

        <div className="container mb-5">
          <h3 className="text-center mb-4">Our Brands</h3>
          <p>
            Under Sweety Jewels Private Limited, we have three brands:
          </p>
          <ul className="list-unstyled ">
            <li>1. Pretty Women</li>
            <li>2. Belle Blue</li>
            <li>3. Zingg</li>
          </ul>
          <p >
            We have exclusive stores at <span className="font-weight-bold" style={{ color: 'black',fontSize:'15px' }}>Hyderabad, Vijayawada, Kolkata, Bhopal & Varanasi Airports</span>, and we are rapidly expanding our reach to various locations.
          </p>
        </div>

        <div className="container mb-5">
          <h3 className="text-center mb-4">Why Choose Us?</h3>
          <p>
            We are a trustworthy brand backed by a group with over a decade of experience in this field.
          </p>
          <ul className="list-unstyled">
            <li>◦ Quality equal to international brands</li>
            <li>◦ A brand that understands consumer needs</li>
            <li>◦ Unique yet aspirational</li>
            <li>◦ Guarantees a distinctive identity</li>
            <li>◦ Affordable yet trendy and fashionable</li>
          </ul>
        </div>
      </Container>
      <Footer />
      </div>
    </>
  );
};

export default About;
