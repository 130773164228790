import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import { FiBox } from 'react-icons/fi';
import axios from 'axios';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const LoDashboard = () => {
  const [orders, setOrders] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState({});
  const [metrics, setMetrics] = useState({ orders: 0, profit: 0, loss: 0, success: 0, pending: 0 });

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersResponse = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details');
        const ordersData = ordersResponse.data;

        const statuses = {};
        await Promise.all(ordersData.map(async (order) => {
          try {
            const dispatchResponse = await axios.get(`https://sjpapi.prettywomen.in/API/v1/get-dispatch/${order.orderId}`);
            const dispatchData = dispatchResponse.data;

            statuses[order.orderId] = {
              status: dispatchData.flag === 1 ? 'success' : 'pending',
              trackingId: dispatchData.trackingId,
              profit: order.profit || 0,
              loss: order.loss || 0,
            };
          } catch (error) {
            console.error(`Error fetching dispatch details for order ${order.orderId}:`, error);
            statuses[order.orderId] = { status: 'pending', trackingId: 'N/A', profit: 0, loss: 0 };
          }
        }));

        setOrders(ordersData);
        setOrderStatuses(statuses);
        calculateMetrics(ordersData, statuses);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const calculateMetrics = (orders, statuses) => {
    let successCount = 0;
    let pendingCount = 0;

    orders.forEach(order => {
      if (statuses[order.orderId]?.status === 'success') {
        successCount += 1;
      } else if (statuses[order.orderId]?.status === 'pending') {
        pendingCount += 1;
      }
    });

    setMetrics({
      orders: orders.length,
      success: successCount,
      pending: pendingCount,
    });
  };

  const data = [
    { name: 'Orders', value: metrics.orders },
    { name: 'Success', value: metrics.success },
    { name: 'Pending', value: metrics.pending },
  ];

  return (
    <div className="d-flex">
      <Sidebar />
      <div className='w-100'>
        <Header />
        <div className="container mt-4" style={{ fontFamily: 'Nexa, sans-serif' }}>
          <h3>Pretty Women Logistics Dashboard</h3>
          <div className="row mt-4">
            <DashboardCard title="ORDERS" count={metrics.orders} icon={<FiBox style={{ cursor: 'pointer' }} />} backgroundColor="#b42c2c" />
            <DashboardCard title="SUCCESS" count={metrics.success} icon={<FiBox style={{ cursor: 'pointer' }} />} backgroundColor="success" />
            <DashboardCard title="PENDING" count={metrics.pending} icon={<FiBox style={{ cursor: 'pointer' }} />} backgroundColor="#ffc107" />
          </div>

          <div className="mt-4">
            <h4>Orders, Profit and Loss Overview</h4>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#82ca9d">
                  <LabelList dataKey="value" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardCard = ({ title, count, icon, backgroundColor }) => (
  <div className="col-md-2 mb-4">
    <div className="card" style={{ backgroundColor }}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{title}</h5>
          {icon}
        </div>
        <h1 className="mt-3">{count}</h1>
      </div>
    </div>
  </div>
);

export default LoDashboard;
