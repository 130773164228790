// rootReducer.js
import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import wishlistReducer from './wishlistReducer'; // Import the wishlist reducer

const rootReducer = combineReducers({
  cart: cartReducer,
  wishlist: wishlistReducer, // Add wishlist reducer here
  // Add other reducers here if needed
});

export default rootReducer;
