import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { div, Row, Col, Form, Button, Alert, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure you have Bootstrap CSS loaded
import Logo1 from '../Pages/Logo1.png';
import ReCAPTCHA from "react-google-recaptcha";


const Emailverification = () => {
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://sjpapi.prettywomen.in/API/v1/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });

      const data = await response.json();

      if (response.ok) {
        setVerified(true);
        setError('');
        alert('Email verified successfully! Product has been added to your cart.');
        alert('Please log in to check the product.');

        navigate('/login');
      } else {
        setError(data.error || 'Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setError('Verification failed. Please try again.');
    }
  };

  return (
    <>   
    <div className="d-flex justify-content-center align-items-center min-vh-100 ">
      <Row style={{ fontFamily: 'Nexa, sans-serif' }}>
        <Col  className=" align-items-center p-5 rounded shadow bg-light">
        <div className="d-flex align-items-center">
            <Image src={Logo1} alt="Logo" width={150} rounded className="me-5" />
            <h6 className="mb-0">Email Verification</h6>
          </div>
         
            {!verified ? (
              <Form onSubmit={handleSubmit}>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="otp">
                  <Form.Label>OTP</Form.Label>
                  <Form.Control 
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={handleOTPChange}
                    required
                  />
                </Form.Group>
                <ReCAPTCHA className='mb-2'
              sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU"             
           />
                <Button variant="danger btn-sm " type="submit">
                  Verify
                </Button>
              </Form>
            ) : (
              <Alert variant="success">Email verified!</Alert>
            )}
         
        </Col>
      </Row>
    </div>
    </>
  );
};

export default Emailverification;
