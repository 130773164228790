import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Table, Tabs, Tab, Pagination } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Account = () => {
  const [activeKey, setActiveKey] = useState('orders');
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');


  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40;

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details');
        setTransactions(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page when the filters change
  }, [startDate, endDate]);


  const filterTransactions = () => {
    return transactions.filter(transaction => {
      const transactionDate = new Date(transaction.transactionCreatedAt);
      const matchesDateRange = (!startDate || transactionDate >= startDate) && (!endDate || transactionDate <= endDate);
      const matchesSearchQuery = transaction.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                  transaction.orderId.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                  transaction.paymentTransactionId.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesDateRange && matchesSearchQuery;
    });
  };

  const downloadExcel = () => {
    const filteredTransactions = filterTransactions();
    const worksheet = XLSX.utils.json_to_sheet(filteredTransactions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');
    XLSX.writeFile(workbook, 'transactions.xlsx');
  };

  const downloadPDF = () => {
    const filteredTransactions = filterTransactions();
    const doc = new jsPDF();
    doc.autoTable({
      head: [['User ID', 'Customer Name', 'Payment Transaction ID', 'Order ID', 'Amount', 'Payment Mode', 'Date']],
      body: filteredTransactions.map(transaction => [
        transaction.userId,
        transaction.username,
        transaction.paymentTransactionId,
        transaction.orderId,
        (transaction.amount / 100).toFixed(2),
        transaction.paymentMode,
        new Date(transaction.transactionCreatedAt).toLocaleString(),
      ]),
    });
    doc.save('transactions.pdf');
  };

  const handleShowOrderModal = (order) => {
    setSelectedOrder(order);
    setShowOrderModal(true);
  };

  const handleCloseOrderModal = () => {
    setShowOrderModal(false);
    setSelectedOrder(null);
  };

  const handleShowTransactionModal = async (transactionId) => {
    setLoading(true);
    try {
      const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details', {
        params: { orderId: transactionId }
      });
      const transaction = response.data.find(t => t.transactionId === transactionId);
      setSelectedTransaction(transaction);
      setShowTransactionModal(true);
    } catch (error) {
      console.error('Error fetching transaction details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseTransactionModal = () => {
    setShowTransactionModal(false);
    setSelectedTransaction(null);
  };

  // Calculate pagination
  const filteredTransactions = filterTransactions();
  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);


  const [userType, setUserType] = useState('');

  useEffect(() => {
    const type = sessionStorage.getItem('userType');
    setUserType(type);
  }, []);


  const handleSearch = (e) => {
    e.preventDefault(); // Prevent the default form submission
    setCurrentPage(1); // Reset to the first page on search
  };


  return (
    <div className="d-flex">
      <Sidebar />
      <div className="w-100">
        <Header />
        <div className="container mt-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
          <div className="mb-3">
            <div style={{ marginLeft: '40px' }}>
              <form className="d-flex" role="search" onSubmit={handleSearch}>
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Button type="submit" className="btn btn-sm btn-primary ms-2">Search</Button>
              </form>
            </div>
            <h4 className='text-center mt-3'><strong>Accountant</strong></h4>
            {userType === 'admin' && (
              <div className="d-flex align-items-center">
                <label className="me-2">From:</label>
                <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                <label className="me-2 ms-3">To:</label>
                <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
                <div className="ms-3">
                  <Button onClick={downloadExcel} className="me-2 btn-sm">Download Excel</Button>
                  <Button onClick={downloadPDF} className='btn-sm'>Download PDF</Button>
                </div>
              </div>
            )}
          </div>

          <Tabs id="account-tabs" activeKey={activeKey} onSelect={(k) => setActiveKey(k)} className="mb-3">
            <Tab eventKey="orders" title="Order Management">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Customer Name</th>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTransactions
                    .sort((a, b) => new Date(b.transactionCreatedAt) - new Date(a.transactionCreatedAt)) // Sort by date in descending order
                    .map((order) => (
                      <tr key={order.id}>
                        <td>{order.userId}</td>
                        <td>{order.username}</td>
                        <td>{order.orderId}</td>
                        <td>{new Date(order.transactionCreatedAt).toLocaleString()}</td>
                        <td>{(order.amount / 100 * 1).toFixed(2)}</td>
                        <td>
                          {order.transactionStatus.trim().toUpperCase() === 'SUCCESS' ? (
                            <FaCheckCircle style={{ color: 'green' }} />
                          ) : (
                            <FaTimesCircle style={{ color: 'red' }} />
                          )}
                        </td>
                        <td>
                          <Button className='btn-sm bg-dark' onClick={() => handleShowOrderModal(order)}>View Details</Button>
                        </td>
                      </tr>
                    ))}

                </tbody>
              </Table>
              <Pagination className='justify-content-center'>
                <Pagination.First onClick={() => setCurrentPage(1)} />
                <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                {[...Array(totalPages)].map((_, i) => (
                  <Pagination.Item key={i} active={i + 1 === currentPage} onClick={() => setCurrentPage(i + 1)}>
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} />
                <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
              </Pagination>
            </Tab>
            <Tab eventKey="transactions" title="Payment Transactions">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Customer Name</th>
                    <th>Payment Transaction ID</th>
                    <th>Order ID</th>
                    <th>Product Amount</th>
                    <th>shipping Amount</th>
                    <th>Payment Mode</th>
                    <th>Date</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTransactions
                    .sort((a, b) => new Date(b.transactionCreatedAt) - new Date(a.transactionCreatedAt))
                    .map((transaction) => (
                      <tr key={transaction.paymentTransactionId}>
                        <td>{transaction.userId}</td>
                        <td>{transaction.username}</td>
                        <td>{transaction.paymentTransactionId}</td>
                        <td>{transaction.orderId}</td>
                        <td>{(transaction.amount / 100).toFixed(2)}</td>
                        <td>{transaction.shippingCharge}</td>
                        <td>{transaction.paymentMode}</td>
                        <td>{new Date(transaction.transactionCreatedAt).toLocaleString()}</td>
                        <td>
                          <Button className='btn-sm bg-dark' onClick={() => handleShowTransactionModal(transaction.transactionId)}>View Details</Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Pagination className='justify-content-center'>
                <Pagination.First onClick={() => setCurrentPage(1)} />
                <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                {[...Array(totalPages)].map((_, i) => (
                  <Pagination.Item key={i} active={i + 1 === currentPage} onClick={() => setCurrentPage(i + 1)}>
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} />
                <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
              </Pagination>
            </Tab>
          </Tabs>
        </div>
      </div>

      {/* Modal for order details */}
      <Modal show={showOrderModal} onHide={handleCloseOrderModal}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
            {loading ? (
              <p>Loading details...</p>
            ) : (
              selectedOrder ? (
                <div>
                  <p><strong>User ID:</strong> {selectedOrder.userId}</p>
                  <p><strong>Order ID:</strong> {selectedOrder.orderId}</p>
                  <p><strong>Amount:</strong> {(selectedOrder.amount / 100 * 1).toFixed(2)}</p>
                  <p><strong>Status:</strong> {selectedOrder.transactionStatus}</p>
                  <p><strong>Product Details:</strong> {selectedOrder.productdetails}</p>
                  <img src={selectedOrder.productImage} alt={selectedOrder.productName} style={{ width: '100px' }} />
                  <p><strong>Name:</strong> {selectedOrder.productName}</p>
                  <p>Product Price: {selectedOrder.productPrice}</p>
                </div>
              ) : (
                <p>No details available</p>
              )
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal for transaction details */}
      <Modal show={showTransactionModal} onHide={handleCloseTransactionModal}>
        <Modal.Header closeButton>
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
            {loading ? (
              <p>Loading details...</p>
            ) : (
              selectedTransaction ? (
                <div>
                  <p><strong>Transaction ID:</strong> {selectedTransaction.transactionId}</p>
                  <p><strong>Order ID:</strong> {selectedTransaction.orderId}</p>
                  <p><strong>User ID:</strong> {selectedTransaction.userId}</p>
                  <p><strong>Username:</strong> {selectedTransaction.username}</p>
                  <p><strong>Email:</strong> {selectedTransaction.email}</p>
                  <p><strong>Mobile:</strong> {selectedTransaction.userMobile}</p>
                  <p><strong>Product Name:</strong> {selectedTransaction.productName}</p>
                  <img src={selectedTransaction.productImage} alt={selectedTransaction.productName} style={{ width: '100px' }} />
                  <p><strong>Amount:</strong> {(selectedTransaction.amount / 100 * 1).toFixed(2)}</p>
                  <p><strong>Status:</strong> {selectedTransaction.transactionStatus}</p>
                  <p><strong>Payment Transaction ID:</strong> {selectedTransaction.paymentTransactionId}</p>
                  <p><strong>Created At:</strong> {new Date(selectedTransaction.transactionCreatedAt).toLocaleString()}</p>
                  <p><strong>Transaction Mobile Number:</strong> {selectedTransaction.transactionMobileNumber || 'N/A'}</p>
                  <p><strong>State:</strong> {selectedTransaction.transactionState}</p>
                  <p><strong>Payment Mode:</strong> {selectedTransaction.paymentMode}</p>
                  <p><strong>Product Price:</strong> {selectedTransaction.productPrice}</p>
                  <p><strong>Product Stylecode:</strong> {selectedTransaction.stylecode}</p>
                </div>
              ) : (
                <p>No details available</p>
              )
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Account;


