import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import axios from 'axios';
import './Checkout.css';
import { useNavigate } from 'react-router-dom';
import LandingHeader from '../LandingPage/LandingHeader';
import { FaShippingFast } from 'react-icons/fa';

const PaymentPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [showAdditionalInput, setShowAdditionalInput] = useState(false);
  const [useSameAddress, setUseSameAddress] = useState(true);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [discountCode, setDiscountCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [payUrl, setPayUrl] = useState('');
  const [isPayConfirm, setIsPayConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [couponCode, setCouponCode] = useState('');

  useEffect(() => {
    if (userData) {
      setName(userData.username);
      setMobile(userData.mobile);
      setCouponCode(userData.couponcode || '');
    }
  }, [userData]);


  const [billingAddress, setBillingAddress] = useState({
    address: '',
    houseNo: '',
    city: '',
    state: '',
    zip: '',
    whatsapp: '',
  });

  const [shippingAddress, setShippingAddress] = useState({
    address: '',
    houseNo: '',
    city: '',
    state: '',
    zip: '',
  });

  const navigate = useNavigate();
  const userEmail = localStorage.getItem('emailUser');
  console.log(userEmail)

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userEmail) return;
      try {
        const response = await axios.get(`https://sjpapi.prettywomen.in/API/v1/api/users?email=${encodeURIComponent(userEmail)}`);
        setUserData(response.data)
        console.log(response.data)
        setUserData(response.data[0] || null);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchUserData();
  }, [userEmail]);

  const [alert, setAlert] = useState({ message: '', type: '' });

  const userId = localStorage.getItem('userId')

  const fetchCartItems = async () => {
    try {
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/cart', {
        userId  // Send userId in the request body
      });
      setCartItems(response.data);
      const cartLength = response.data.length
      localStorage.setItem('cartlength', cartLength)

      console.log(cartLength)


    } catch (err) {
      setAlert({ message: 'Failed to fetch cart items. Please try again later.', type: 'error' });
      console.error('Failed to fetch cart items:', err);
    }
  };

  useEffect(() => {
    fetchCartItems();

  }, []);


  useEffect(() => {
    const calculateTotalPrice = () => {
      const price = cartItems.reduce((total, cartItem) => {
        const product = cartItems.find(p => p.id === cartItem.id);
        if (product) {
          const offerPrice = product.pprice - (product.pprice * product.discount) / 100;
          return total + (offerPrice * cartItem.quantity);
        }
        return total;
      }, 0);
      setTotalPrice(price);
      setDiscountedPrice(price);
    };

    calculateTotalPrice();
  }, [cartItems, productList]);

  const handleRadioChange = (e) => {
    setShowAdditionalInput(e.target.value === 'no');
  };

  const handleUseSameAddressChange = (e) => {
    setUseSameAddress(e.target.value === 'yes');
  };

  const handleAddressChange = (type, e) => {
    const { name, value } = e.target;
    if (type === 'billing') {
      setBillingAddress(prev => ({ ...prev, [name]: value }));
    } else {
      setShippingAddress(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const addressToUpdate = useSameAddress ? billingAddress : shippingAddress;

      // Perform address update
      await axios.post('https://sjpapi.prettywomen.in/API/v1/api/update-addresses', {
        userId: userData.id,
        shippingAddress: addressToUpdate,
        billingAddress: addressToUpdate,
        username: name, // Add updated name
        mobile: mobile, // Add updated mobile
      });

      // Perform payment shipCharge: shippingCost,
      const res = await axios.post('https://sjpapi.prettywomen.in/API/v1/pay/v1/pay', {
        payText: totalAmount ,
        userId: userData.id,
        pId: cartItems.map(item => item.productId),
        shipCharge: shippingCost,
      });

      if (res.data.success) {
        setPayUrl(res.data.url);
        window.open(res.data.url);
        setIsPayConfirm(true);
        setOpen(true);
      } else {
        console.error('API Response Error:', res.data.message || 'Unknown error');
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('API Request Error:', error);
      setOpen(false);
      alert('Payment Error');
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };


  const cDiscount = localStorage.getItem("coupon-discount")
  console.log(cDiscount)

  const [cpdiscount, setCpDisc] = useState('')

  const handleApplyDiscount = () => {
    if (!couponCode) {
      console.log("No coupon available for your account.");
      return;
    }

    const discountPercentage = offer ? offer.offer : 0;

    // Ensure cDiscount is a valid number before proceeding
    //const discountPercentage = parseFloat(cDiscount);

    if (isNaN(discountPercentage)) {
      console.error("Invalid discount value");
      return null; 
    }

    // const productTotalWithCoupon = discountedPrice * (1 - (discountPercentage / 100));
    // setCpDisc(productTotalWithCoupon)

    const productTotalWithCoupon = discountedPrice * (1 - (discountPercentage / 100));
    const newTotal = productTotalWithCoupon + shippingCost; // Calculate new total amount with shipping
    setCpDisc(productTotalWithCoupon); // Update discounted price state
    setDisplayTotalAmount(newTotal); 
    setIsCouponApplied(true);
    console.log("Product Total with Coupon:", productTotalWithCoupon);
    return productTotalWithCoupon; 
  };

  const [offer, setOffers] = useState()
  console.log(offer)

  const fetchOffers = async () => {
    try {
      const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/offers/ji'); // Fetch offers from the backend
      setOffers(response.data[0]);
      console.log(response.data)

    } catch (error) {
      console.error('Error fetching offers:', error);
    }
  };

  useEffect(() => {
    fetchOffers()
  }, [])

  function calculateShipping(discountedPrice) {
    let shippingCharges = 0;

    if (discountedPrice < 500) {
      shippingCharges = 120; // Charge 120 if cart value is less than 500
    } else if (discountedPrice < 1500) {
      shippingCharges = 60; // Charge 60 if cart value is less than 1500 but greater than or equal to 500
    }

    return shippingCharges;
  }


  const shippingCost = calculateShipping(discountedPrice);

  // const TotalAmount = Math.round(shippingCost + cpdiscount);
  const totalAmount = (couponCode ? Math.round(cpdiscount) : Math.round(discountedPrice)) + shippingCost;
  const [displayTotalAmount, setDisplayTotalAmount] = useState(0);
  const [initialProductTotal, setInitialProductTotal] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  useEffect(() => {
    const initialTotal = discountedPrice + shippingCost;
    setInitialProductTotal(discountedPrice);
    setDisplayTotalAmount(initialTotal);
  }, [discountedPrice, shippingCost]);

  return (
    <div>
      <LandingHeader />
      <Container className="my-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
        <div className="row justify-content-center">
          <div className='col-md-7'>
            <div>
              <h5>Billing Address</h5>
              <Row>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <input type="text" style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }} value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="mobile">
                  <Form.Label>Mobile</Form.Label>
                  <input type="text" style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }} value={mobile} onChange={(e) => setMobile(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col} controlId="whatsapp">
                  <Form.Label>Is this your Whatsapp Number?</Form.Label>
                  <Col className='d-flex flex-row align-items-center'>
                    <Form.Check type="radio" id="yesOption" label="Yes" name="whatsapp" value="yes" onChange={handleRadioChange} />
                    <Form.Check type="radio" id="noOption" label="No" name="whatsapp" value="no" onChange={handleRadioChange} style={{ marginLeft: '8px' }} />
                  </Col>
                </Form.Group>
              </Row>
              {showAdditionalInput && (
                <Form.Group controlId="whatsapp">
                  <Form.Label>Whatsapp Number</Form.Label>
                  <input type="number" name="whatsapp" required value={billingAddress.whatsapp} style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }} placeholder="Enter whatsapp number"
                    onChange={(e) => handleAddressChange('billing', e)}
                  />
                </Form.Group>
              )}
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <input
                  type="text"
                  name="address"
                  placeholder="Enter address"
                  style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                  required
                  value={billingAddress.address}
                  onChange={(e) => handleAddressChange('billing', e)}
                />
              </Form.Group>
              <Form.Group controlId="houseNo">
                <Form.Label>House.No / Area / Street</Form.Label>
                <input
                  type="text"
                  name="houseNo"
                  placeholder="house.no area, street, nearby landmark"
                  style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                  required
                  value={billingAddress.houseNo}
                  onChange={(e) => handleAddressChange('billing', e)}
                />
              </Form.Group>
              <Row>
                <Form.Group as={Col} controlId="city">
                  <Form.Label>City</Form.Label>
                  <input type="text" name="city" placeholder="Enter city" style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }} required value={billingAddress.city} onChange={(e) => handleAddressChange('billing', e)} />
                </Form.Group>
                <Form.Group as={Col} controlId="state">
                  <Form.Label>State</Form.Label>
                  <input type="text" name="state" placeholder="Enter state" style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }} required value={billingAddress.state} onChange={(e) => handleAddressChange('billing', e)} />
                </Form.Group>
                <Form.Group as={Col} controlId="zip">
                  <Form.Label>Zip Code</Form.Label>
                  <input type="text" name="zip" placeholder="Enter zip code" style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }} required value={billingAddress.zip} onChange={(e) => handleAddressChange('billing', e)} />
                </Form.Group>
              </Row>


              {/* Shipping Address Section */}
              <h5>Shipping Address</h5>
              <Form.Group controlId="useSameAddress">
                <Form.Label>Is the shipping address the same as billing address?</Form.Label>
                <Col className='d-flex flex-row align-items-center'>
                  <Form.Check
                    type="radio"
                    id="sameAddressYes"
                    label="Yes"
                    name="useSameAddress"
                    value="yes"
                    checked={useSameAddress}
                    onChange={handleUseSameAddressChange}
                  />
                  <Form.Check
                    type="radio"
                    id="sameAddressNo"
                    label="No"
                    name="useSameAddress"
                    value="no"
                    checked={!useSameAddress}
                    onChange={handleUseSameAddressChange}
                    style={{ marginLeft: '8px' }}
                  />
                </Col>
              </Form.Group>

              {!useSameAddress && (
                <>
                  <Form.Group controlId="shippingAddress">
                    <Form.Label>Address</Form.Label>
                    <input
                      type="text"
                      name="address"
                      placeholder="Enter shipping address"
                      style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                      required
                      value={shippingAddress.address}
                      onChange={(e) => handleAddressChange('shipping', e)}
                    />
                  </Form.Group>
                  <Form.Group controlId="shippingHouseNo">
                    <Form.Label>House.No / Area / Street</Form.Label>
                    <input
                      type="text"
                      name="houseNo"
                      placeholder="house.no area, street, nearby landmark"
                      required
                      value={shippingAddress.houseNo}
                      style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                      onChange={(e) => handleAddressChange('shipping', e)}
                    />
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} controlId="shippingCity">
                      <Form.Label>City</Form.Label>
                      <input
                        type="text"
                        name="city"
                        placeholder="Enter city"
                        required
                        value={shippingAddress.city}
                        style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                        onChange={(e) => handleAddressChange('shipping', e)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="shippingState">
                      <Form.Label>State</Form.Label>
                      <input
                        type="text"
                        name="state"
                        placeholder="Enter state"
                        required
                        value={shippingAddress.state}
                        style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                        onChange={(e) => handleAddressChange('shipping', e)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="shippingZip">
                      <Form.Label>Zip Code</Form.Label>
                      <input
                        type="text"
                        name="zip"
                        placeholder="Enter zip code"
                        required
                        value={shippingAddress.zip}
                        style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                        onChange={(e) => handleAddressChange('shipping', e)}
                      />
                    </Form.Group>
                  </Row>
                </>
              )}
            </div>
          </div>
          <div className='col-md-5 scrollable-content'>
            {cartItems.map((cartItem) => {
              const offerPrice = Math.round(Number(cartItem.pprice) - (Number(cartItem.pprice) * Number(cartItem.discount)) / 100);

              return (
                <div key={cartItem.productId} className="mb-3">
                  <img src={cartItem.pimage} alt={cartItem.pname} style={{ width: '100px', height: 'auto' }} />
                  <p>{cartItem.pname}</p>
                  <p>Quantity: {cartItem.quantity}</p>
                  <p>Price: Rs. {offerPrice}</p>
                  <p>Total: Rs. {Math.round(offerPrice * cartItem.quantity)}</p>
                </div>
              );
            })}
            <div className="discount-section mt-3">
              <input
                type="text"
                id="discountCode"
                style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                placeholder="Discount code"
                value={couponCode}
                disabled
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button
                className="btn btn-dark mt-2"
                style={{ border: '1px solid darkgrey', padding: '15px', marginLeft: '12px', width: '95%' }}
                onClick={handleApplyDiscount}
                disabled={isCouponApplied}
              >
                Apply
              </button>
            </div>
            <div className="bg-light mt-2">
              <h4>Order Summary</h4>
              <p>Product Amount: Rs. {Math.round(initialProductTotal)}</p>
              <p>Shipping Charges <FaShippingFast />: {shippingCost}</p>
              <p>
                Total Amount:
                {/* <strong>{Math.round(cpdiscount + shippingCost)} </strong>
                <span> (includes shipping: {shippingCost})</span> */}
                 <strong>{Math.round(displayTotalAmount)} </strong>
                 <span> (includes shipping: {shippingCost})</span>
              </p>
            </div>
          </div>
        </div>
        <Button
          variant="dark btn-sm"
          onClick={handleSubmit}
          className="mt-3"
          style={{ border: '1px solid darkgrey', padding: '15px', width: '58%' }}
        >
          Pay Now
        </Button>
      </Container>
      <Footer />
    </div>
  );
};

export default PaymentPage;
