import React, { useState } from 'react';
import Footer from './Footer';
import LandingHeader from '../LandingPage/LandingHeader';

const Faq = () => {
    const [openQuestions, setOpenQuestions] = useState({});

    const toggleQuestion = (question) => {
        setOpenQuestions((prev) => ({
            ...prev,
            [question]: !prev[question]
        }));
    };

    return (
        <>
    
                <LandingHeader />
                <div className='mt-5' style={{ color: '#d47bb1', fontFamily:'Arial, sans-serif',}}>
                    <h1 className='text-center' style={{ fontSize: '50px' }}><strong>F A Q s</strong></h1>
                </div>

                <div className='container d-flex flex-column align-items-center'>
                    <div className='mt-5 text-center' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>
                        <h6
                            style={{ cursor: 'pointer', color: openQuestions.returnExchange ? '#b86cb8' : '#000' }}
                            onClick={() => toggleQuestion('returnExchange')}
                        >
                            CAN I RETURN/EXCHANGE MY ITEM?
                        </h6>
                        {openQuestions.returnExchange && (
                            <p>
                                If you wish to return your online order, Pretty women offers a 28-day returns period from the date your parcel is signed for. For exchanges, return your original order for a refund and place a new order. Return shipping costs are the responsibility of the customer. For more information, please see our <a href="/shippingpolicy">Shipping Policy</a>.
                            </p>
                        )}
                    </div>

                    <div className='mt-5 text-center' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>
                        <h6
                            style={{ cursor: 'pointer', color: openQuestions.trackOrder ? '#b86cb8' : '#000' }}
                            onClick={() => toggleQuestion('trackOrder')}
                        >
                            HOW CAN I TRACK MY ORDER?
                        </h6>
                        {openQuestions.trackOrder && (
                            <p>
                                Simply log into your account to check the status of your order. An email is also sent to update the dispatch of your items.
                                You can also check it on the <a href="https://bluedart.com/" target="_blank" rel="noopener noreferrer">website</a>.
                            </p>
                        )}
                    </div>

                    <div className='mt-5 text-center' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>
                        <h6
                            style={{ cursor: 'pointer', color: openQuestions.signForItem ? '#b86cb8' : '#000' }}
                            onClick={() => toggleQuestion('signForItem')}
                        >
                            DO I HAVE TO SIGN FOR MY ITEM?
                        </h6>
                        {openQuestions.signForItem && (
                            <p>
                                No, you do not need to sign for your item upon delivery. However, please ensure that someone is available to receive the package.
                                If you're purchasing as a guest, you can complete your order without creating an account.
                            </p>
                        )}
                    </div>



                    <div className='mt-5 text-center' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>
                        <h6
                            style={{ cursor: 'pointer', color: openQuestions.amendCancel ? '#b86cb8' : '#000' }}
                            onClick={() => toggleQuestion('amendCancel')}
                        >
                            CAN I AMEND/ CANCEL MY ORDER?
                        </h6>
                        {openQuestions.amendCancel && (
                            <p>
                                Yes, this has to be done within an hour of placing the order.
                                Alternatively, please email us at <a href="mailto:info@prettywomen.in">info@prettywomen.in</a>
                                or visit our <a href="/refund">refund page</a> within an hour of your placed order.
                            </p>
                        )}
                    </div>

                    <div className='mt-5 text-center' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>
                        <h6
                            style={{ cursor: 'pointer', color: openQuestions.missedPromotion ? '#b86cb8' : '#000' }}
                            onClick={() => toggleQuestion('missedPromotion')}
                        >
                            I MISSED OUT ON A PROMOTION, WHAT CAN BE DONE?
                        </h6>
                        {openQuestions.missedPromotion && (
                            <p>
                                Once a promotion is finished, we are unable to offer any discounts past the promotion date. If you are unsure about applying for a discount, please contact us within the duration of the promotion, and we will aim to respond within 2 hours.
                            </p>
                        )}
                    </div>
                </div>


       
            <div className='mt-5'>
                <Footer />
            </div>
        </>
    );
}

export default Faq;
