// import React, { useState, useEffect } from 'react';
// import Sidebar from '../Sidebar/Sidebar';
// import Header from '../Head/Header';
// import axios from 'axios'; // Ensure you have axios installed

// const Offerzone = () => {
//     const [offers, setOffers] = useState([]);
//     const [newOffer, setNewOffer] = useState('');
//     const [editingId, setEditingId] = useState(null);

//     useEffect(() => {
//         fetchOffers();
//     }, []);

//     localStorage.setItem("coupon-discount",newOffer)

//     const fetchOffers = async () => {
//         try {
//             const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/offers/ji'); // Fetch offers from the backend
//             setOffers(response.data);
//             console.log(response.data)

//         } catch (error) {
//             console.error('Error fetching offers:', error);
//         }
//     };

//     const handleAddOffer = async () => {
//         if (newOffer) {
//             try {
//                 const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/offers', { offer: newOffer });
//                 setOffers([...offers, response.data]); // Add the new offer to the state
//                 fetchOffers()
//                 setNewOffer('');

//             } catch (error) {
//                 console.error('Error adding offer:', error);
//             }
//         }
//     };
// console.log(offers)
//     const handleEditOffer = (offer) => {
//         setEditingId(offer.id);
//         setNewOffer(offer.offer);
//     };

//     const handleUpdateOffer = async () => {
//         if (editingId && newOffer) {
//             try {
//                 await axios.put(`https://sjpapi.prettywomen.in/API/v1/offers/${editingId}`, { offer: newOffer });
//                 const updatedOffers = offers.map((offer) =>
//                     offer.id === editingId ? { ...offer, offer: newOffer } : offer
//                 );
//                 setOffers(updatedOffers);
//                 setNewOffer('');
//                 setEditingId(null);
//             } catch (error) {
//                 console.error('Error updating offer:', error);
//             }
//         }
//     };

//     const handleDeleteOffer = async (id) => {
//         try {
//             await axios.delete(`https://sjpapi.prettywomen.in/API/v1/offers/${id}`);
//             const updatedOffers = offers.filter((offer) => offer.id !== id);
//             setOffers(updatedOffers);
//         } catch (error) {
//             console.error('Error deleting offer:', error);
//         }
//     };

//     return (
//         <div className="d-flex">
//             <Sidebar />
//             <div className='w-100'>
//                 <Header />
//                 <div className='container text-center mt-3'>
//                     <h2>Offerzone</h2>
//                     <div className="mt-3">
//                         <input 
//                             type="text" 
//                             value={newOffer} 
//                             onChange={(e) => setNewOffer(e.target.value)} 
//                             placeholder="Enter offer (e.g., 10%)" 
//                             className="form-control"
//                         />
//                         <button 
//                             onClick={editingId ? handleUpdateOffer : handleAddOffer}
//                             className="btn btn-primary btn-sm mt-2"
//                         >
//                             {editingId ? 'Update Offer' : 'Add Offer'}
//                         </button>
//                     </div>
//                     <table className="table table-striped">
//                         <thead>
//                             <tr>
//                                 <th>SN.O</th>
//                                 <th>Offer</th>
//                                 <th>Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {offers.map((offer, index) => (
//                                 <tr key={offer.id}>
//                                     <td>{index + 1}</td>
//                                     <td>{offer.offer}</td>
//                                     <td>
//                                         <button 
//                                             onClick={() => handleEditOffer(offer)} 
//                                             className="btn btn-warning btn-sm me-2"
//                                         >
//                                             Edit
//                                         </button>
//                                         <button 
//                                             onClick={() => handleDeleteOffer(offer.id)} 
//                                             className="btn btn-danger btn-sm ml-2"
//                                         >
//                                             Delete
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Offerzone;



import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import axios from 'axios';

const Offerzone = () => {
    const [offers, setOffers] = useState([]);
    const [newOffer, setNewOffer] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null); // Add state for the selected image

    useEffect(() => {
        fetchOffers();
    }, []);

    localStorage.setItem("coupon-discount", newOffer);

    const fetchOffers = async () => {
        try {
            const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/offers/ji');
            setOffers(response.data);
        } catch (error) {
            console.error('Error fetching offers:', error);
        }
    };

    // const handleAddOffer = async () => {
    //     if (newOffer && selectedImage) {
    //         const formData = new FormData();
    //         formData.append('offer', newOffer);
    //         formData.append('oimage', selectedImage); // Append the image file

    //         try {
    //             const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/offers', formData, {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                 },
    //             });

    //             setOffers([...offers, response.data]);
    //             fetchOffers();
    //             setNewOffer('');
    //             setSelectedImage(null);
    //         } catch (error) {
    //             console.error('Error adding offer:', error);
    //         }
    //     }
    // };

    const handleAddOffer = async () => {
        if (newOffer) {
            const formData = new FormData();
            formData.append('offer', newOffer);
            if (selectedImage) {
                formData.append('oimage', selectedImage);
            }
    
            try {
                const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/offers', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                console.log('Response:', response.data); // Log the response
    
                setOffers([...offers, response.data]);
                fetchOffers(); // Consider whether you need to fetch again
                setNewOffer('');
                setSelectedImage(null);
            } catch (error) {
                console.error('Error adding offer:', error);
            }
        } else {
            console.warn('Offer is empty'); // Warn if offer is empty
        }
    };

    
    const handleEditOffer = (offer) => {
        setEditingId(offer.id);
        setNewOffer(offer.offer);
        setSelectedImage(null);
    };

    const handleUpdateOffer = async () => {
        if (editingId && newOffer) {
            try {
                await axios.put(`https://sjpapi.prettywomen.in/API/v1/offers/${editingId}`, { offer: newOffer });
                const updatedOffers = offers.map((offer) =>
                    offer.id === editingId ? { ...offer, offer: newOffer } : offer
                );
                setOffers(updatedOffers);
                setNewOffer('');
                setEditingId(null);
                setSelectedImage(null);
            } catch (error) {
                console.error('Error updating offer:', error);
            }
        }
    };

    const handleDeleteOffer = async (id) => {
        try {
            await axios.delete(`https://sjpapi.prettywomen.in/API/v1/offers/${id}`);
            const updatedOffers = offers.filter((offer) => offer.id !== id);
            setOffers(updatedOffers);
        } catch (error) {
            console.error('Error deleting offer:', error);
        }
    };

    const handleImageChange = (event) => {
        setSelectedImage(event.target.files[0]); // Set the selected image
    };

    return (
        <div className="d-flex">
            <Sidebar />
            <div className='w-100'>
                <Header />
                <div className='container text-center mt-3'>
                    <h2>Offerzone</h2>
                    <div className="mt-3">
                        <input type="text" value={newOffer} onChange={(e) => setNewOffer(e.target.value)} placeholder="Enter offer (e.g., 10%)" className="form-control" />
                        <input type="file" onChange={handleImageChange} className="form-control mt-2" />
                        <button onClick={editingId ? handleUpdateOffer : handleAddOffer} className="btn btn-primary btn-sm mt-2">
                            {editingId ? 'Update Offer' : 'Add Offer'}
                        </button>
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>SN.O</th>
                                <th>Offer</th>
                                <th>Offer image</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {offers.map((offer, index) => (
                                <tr key={offer.id}>
                                    <td>{index + 1}</td>
                                    <td>{offer.offer}</td>
                                    <td>
                                        {offer.offerImg ? (
                                            <img
                                                src={offer.offerImg}
                                                alt={`Offer ${index + 1}`}
                                                style={{ width: '100px', height: '50px' }} // Adjust size as needed
                                            />
                                        ) : (
                                            'No image available'
                                        )}
                                    </td>
                                    <td>
                                        <button
                                            onClick={() => handleEditOffer(offer)}
                                            className="btn btn-warning btn-sm me-2"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleDeleteOffer(offer.id)}
                                            className="btn btn-danger btn-sm ml-2"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    );
}

export default Offerzone;
