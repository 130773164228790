import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from '../Footer/Footer';

const Guestlogin = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [emailVerified, setEmailVerified] = useState(false); // Track if the email is verified
  const [otpSent, setOtpSent] = useState(false); // Track if OTP is sent
  const navigate = useNavigate(); // Hook for navigation

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleEmailVerification = async () => {
    try {
      const response = await fetch('https://sjpapi.prettywomen.in/API/v1/send-otp', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setOtpSent(true); // Mark OTP as sent
        setEmailVerified(true); // Update the state to show OTP input
        setError('');
        alert('OTP sent to your email!');
      } else {
        setError(data.error || 'Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('Failed to send OTP. Please try again.');
    }
  };

  const handleOtpVerification = async () => {
    try {
      const response = await fetch('https://sjpapi.prettywomen.in/API/v1/guest/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });

      const data = await response.json();

      if (response.ok) {
        setEmailVerified(true); 
        localStorage.setItem('userId', data.user.id);
        localStorage.setItem('emailUser', email);
        localStorage.setItem('userData', JSON.stringify({ email: data.user.email, id: data.user.id }));// Ensure emailVerified is true for navigation
        setError('');
        alert('Email verified successfully!');
        navigate('/');
      } else {
        setError(data.error || 'Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Verification failed. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otpSent) {
      await handleEmailVerification();
    } else {
      await handleOtpVerification();
    }
  };

  return (
    <>
      <LandingHeader />
      <div className='container'style={{fontFamily:'Arial, sans-serif',fontSize:'13px'}}>
        <div className='row justify-content-center'>
          <div className='col-lg-6 col-md-8 col-sm-12 p-4'>
            <h3 className='text-center mb-4'><strong>Guest Login</strong></h3>
            <form onSubmit={handleSubmit}>
              {!otpSent ? (
                <>
                  <div className='mb-3'>
                    <input
                      type='email'
                      placeholder='Email'
                      className='form-control'
                      value={email}
                      onChange={handleEmailChange}
                      required
                      aria-label='Email Address'
                    />
                  </div>
                  <div className='d-grid mb-3'>
                    <button type='submit' className='btn btn-dark'>
                      Send OTP
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className='mb-3'>
                    <input
                      type='text'
                      placeholder='Enter OTP'
                      className='form-control'
                      value={otp}
                      onChange={handleOtpChange}
                      required
                      aria-label='OTP'
                    />
                  </div>
                  <div className='d-grid mb-3'>
                    <button type='submit' className='btn btn-dark'>
                      Verify OTP
                    </button>
                  </div>
                </>
              )}
              {error && <div className='text-danger mt-2'>{error}</div>}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Guestlogin;






// const handleOtpVerification = async () => {
//   try {
//     // Retrieve the cart from sessionStorage
//     const sessionCart = JSON.parse(sessionStorage.getItem('cart')) || [];

//     // Prepare the request body with email, OTP, and cart data
//     const requestBody = { 
//       email, 
//       otp,
//       sessionCart  // Add sessionCart data to the request body
//     };

//     const response = await fetch('http://localhost:3008/guest/verify-otp', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestBody),
//     });

//     const data = await response.json();

//     if (response.ok) {
//       // Set email as verified
//       setEmailVerified(true);

//       // Store user data in localStorage
//       localStorage.setItem('userId', data.user.id);
//       localStorage.setItem('emailUser', email);
//       localStorage.setItem('userData', JSON.stringify({ email: data.user.email, id: data.user.id }));

//       // Clear any error state
//       setError('');
      
//       // Optionally, clear the cart from sessionStorage if it was successfully added
//       sessionStorage.removeItem('cart'); // Optional, if you want to clear the cart after successful login

//       // Display success alert and navigate to home page
//       alert('Email verified successfully!');
//       navigate('/');
//     } else {
//       // Handle any error returned from the backend
//       setError(data.error || 'Verification failed. Please try again.');
//     }
//   } catch (error) {
//     console.error('Error verifying OTP:', error);
//     setError('Verification failed. Please try again.');
//   }
// };