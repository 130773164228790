

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaHeart, FaRupeeSign, FaTrashAlt } from 'react-icons/fa';

const CartItem = ({ data,onRemove }) => {
  const { id, userId, productId, pname, pprice, pimage, discount, quantity } = data;
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);

  // Function to calculate total price
  const calculateTotalPrice = (quantity) => {
    const price = parseFloat(pprice);
    const discountValue = parseFloat(discount) || 0;
    if (discountValue) {
      return (price - (price * discountValue) / 100) * quantity;
    } else {
      return price * quantity;
    }
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(localQuantity));
  }, [localQuantity]);

  const handleMinusQuantity = async () => {
    if (localQuantity > 1) {
      try {
        setIsUpdating(true);
        setError(null);
        const response = await axios.put(`https://sjpapi.prettywomen.in/API/v1/api/cart/${id}`, { 
          quantity: localQuantity - 1,
          userId,
          productId 
        });
        console.log('Update Response:', response.data);
        setLocalQuantity(response.data.newQuantity);
      } catch (err) {
        console.error('Error updating quantity:', err.response ? err.response.data : err.message);
        setError('Failed to update quantity');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  const handlePlusQuantity = async () => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await axios.put(`https://sjpapi.prettywomen.in/API/v1/api/cart/${id}`, { 
        quantity: localQuantity + 1,
        userId,
        productId 
      });
      console.log('Update Response:', response.data);
      setLocalQuantity(response.data.newQuantity);
    } catch (err) {
      console.error('Error updating quantity:', err.response ? err.response.data : err.message);
      setError('Out of Stock');
    } finally {
      setIsUpdating(false);
    }
  };

  // const handleMinusQuantity = async () => {
  //   // Check if the quantity is greater than 1
  //   if (localQuantity > 1) {
  //     // Decrement the quantity locally first (in state)
  //     const updatedQuantity = localQuantity - 1;
  //     setLocalQuantity(updatedQuantity);
  
  //     // Update sessionStorage if the product exists there
  //     const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
  //     const productIndex = cart.findIndex(item => item.productId === productId);
  
  //     if (productIndex !== -1) {
  //       cart[productIndex].quantity = updatedQuantity;
  //       sessionStorage.setItem('cart', JSON.stringify(cart));
  //     }
  
  //     // Then, proceed with the API call to update the backend
  //     try {
  //       setIsUpdating(true);
  //       setError(null);
  
  //       const response = await axios.put(`http://localhost:3008/api/cart/${id}`, {
  //         quantity: updatedQuantity,
  //         userId,
  //         productId,
  //       });
  //       console.log('Update Response:', response.data);
  
  //       // Update the local state with the new quantity from the API response
  //       setLocalQuantity(response.data.newQuantity);
  
  //     } catch (err) {
  //       console.error('Error updating quantity:', err.response ? err.response.data : err.message);
  //       setError('Failed to update quantity');
  //     } finally {
  //       setIsUpdating(false);
  //     }
  //   }
  // };
  
  // const handlePlusQuantity = async () => {
  //   // Increment the quantity locally first (in state)
  //   const updatedQuantity = localQuantity + 1;
  //   setLocalQuantity(updatedQuantity);
  
  //   // Update sessionStorage if the product exists there
  //   const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
  //   const productIndex = cart.findIndex(item => item.productId === productId);
  
  //   if (productIndex !== -1) {
  //     cart[productIndex].quantity = updatedQuantity;
  //     sessionStorage.setItem('cart', JSON.stringify(cart));
  //   }
  
  //   // Proceed with the API call to update the backend
  //   try {
  //     setIsUpdating(true);
  //     setError(null);
  
  //     const response = await axios.put(`http://localhost:3008/api/cart/${id}`, {
  //       quantity: updatedQuantity,
  //       userId,
  //       productId,
  //     });
  //     console.log('Update Response:', response.data);
  
  //     // Update the local state with the new quantity from the API response
  //     setLocalQuantity(response.data.newQuantity);
  
  //   } catch (err) {
  //     console.error('Error updating quantity:', err.response ? err.response.data : err.message);
  //     setError('Out of Stock');
  //   } finally {
  //     setIsUpdating(false);
  //   }
  // };
  


  // const handleRemoveFromCart = async () => {
  //   try {
  //     setError(null);
  //     await axios.delete(`http://localhost:3008/api/cart/${id}`);
  //     onRemove(id);  
  //   } catch (err) {
  //     console.error('Error removing item from cart:', err.response ? err.response.data : err.message);
  //     setError('Failed to remove item from cart');
  //   } finally {
  //     setIsUpdating(false);
  //   }
  // };
  const handleRemoveFromCart = async () => {
    try {
      setError(null); // Reset any previous error
  
      // Check if the user is logged in
      const userId = localStorage.getItem('userId'); // Or however you track the logged-in user
  
      if (userId) {
        // If user is logged in, make a DELETE request to the backend API
        await axios.delete(`https://sjpapi.prettywomen.in/API/v1/api/cart/${id}`);
        onRemove(id); // Call the parent handler to remove from local state
      } else {
        // If user is not logged in, remove the item from sessionStorage
        const cartFromSession = JSON.parse(sessionStorage.getItem('cart')) || [];
        
        // Filter out the item with the given id
        const updatedCart = cartFromSession.filter(item => item.id !== id);
        
        // Update sessionStorage with the modified cart
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));
        sessionStorage.setItem('cartlength', updatedCart.length); // Update the cart length in sessionStorage
  
        onRemove(id); // Call the parent handler to remove from local state (if necessary)
      }
    } catch (err) {
      console.error('Error removing item from cart:', err.response ? err.response.data : err.message);
      setError('Failed to remove item from cart'); // Display error message
    } finally {
      setIsUpdating(false); // Reset loading state
    }
  };
  
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }} className='col-lg-8 shadow p-1 m-2 d-flex flex-row position-relative'>
      <img src={pimage} alt="Product" style={{ borderRadius: '10px', objectFit: 'cover', width: '160px', height: 'auto', marginRight: '10px' }} />
      <div className='d-flex flex-column flex-grow-1'>
        <h6 className='mb-4 mt-3'>{pname}</h6>
        <p className='mb-0'>
          Price:
          {discount ? (
            <>
              <span style={{ color: 'green', fontWeight: 'bold' }}>
                <FaRupeeSign /> {Math.round(calculateTotalPrice(localQuantity) / localQuantity)}
              </span>
              <span
                style={{
                  textDecoration: 'line-through',
                  color: '#888',
                  fontSize: '0.85rem',
                  marginLeft: '5px',
                }}
              >
                <FaRupeeSign /> {Math.round(pprice)}
              </span>
              <span style={{ color: '#d1235b', fontSize: '0.85rem', marginLeft: '5px' }}>
                ({discount}% Off)
              </span>
            </>
          ) : (
            <span style={{ color: '#d1235b', fontWeight: 'bold' }}>
              <FaRupeeSign /> {Math.round(pprice)}
            </span>
          )}
        </p>
        <p>Total Price: <FaRupeeSign /> {Math.round(totalPrice)}</p>
        {error && <p className='text-danger'>{error}</p>}
        <div className='d-flex align-items-center mt-4'>
          <button className='btn btn-sm me-2' style={{ borderColor: 'lightgrey' }} onClick={handleRemoveFromCart}> <FaTrashAlt /> Remove Item</button>
          <button className='btn btn-sm' style={{ borderColor: 'lightgrey' }} disabled={isUpdating}> <FaHeart /> Move to Wishlist</button>
        </div>
        <div className='flex-column align-items-end position-absolute' style={{ top: '10px', right: '10px' }}>
          <button className='btn btn-sm mb-2' style={{ borderColor: 'lightgrey' }} onClick={handleMinusQuantity} disabled={isUpdating}>-</button>
          <span className='mx-2'>{localQuantity}</span>
          <button className='btn btn-sm mb-2' style={{ borderColor: 'lightgrey' }} onClick={handlePlusQuantity} disabled={isUpdating}>+</button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
