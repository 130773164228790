import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Import Axios
import LandingHeader from './LandingHeader';
import Footer from '../Footer/Footer';

const MyAccount = () => {
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || ''); // Assuming userEmail is stored in localStorage
  const [userData, setUserData] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch user data based on userEmail
  useEffect(() => {
    const fetchUserData = async () => {
      if (!userEmail) return;
      try {
        const response = await axios.get(`https://sjpapi.prettywomen.in/API/v1/api/users?email=${encodeURIComponent(userEmail)}`);
        setUserData(response.data[0] || null);
        setAddresses(response.data[0]?.addresses || []);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userEmail]);

  const handleLogout = () => {
    console.log('Logging out...');
    localStorage.removeItem('userData');
    localStorage.removeItem('userId');
    localStorage.removeItem('emailUser');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('hasSeenModal'); // Clear modal flag on logout
    navigate('/');
  };

  return (
    <>
      <LandingHeader />
      <div className="container my-5" style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
        <h3 className="mb-4">My Account</h3>
        <button
          className="btn btn-outline-dark mb-4"
          onClick={handleLogout}
        >
          Log out
        </button>
{/* 
        <div className="row mb-4">
          <div className="col-md-6">
            <div className="border p-3">
              <h4>Order History</h4>
              <p>You haven't placed any orders yet.</p>
            </div>
          </div>
        </div> */}

        <div className="row mb-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <Link to="/cart">
              <button
                className="btn btn-outline-dark"
                style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
              >
                Shopping Cart
              </button>
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/wishlist">
              <button
                className="btn btn-outline-dark"
                style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
              >
                Wishlist
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyAccount;
