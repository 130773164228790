import React from 'react';
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Refund = () => {
  return (
    <>
      <LandingHeader />
      <div className="container mt-5" style={{fontFamily:'Arial, sans-serif',fontSize:'13px'}}>
        <div className="row">
          <div className="col-md-12">
            <h2 className="mb-4 text-center"style={{fontSize:'50px', color: '#d47bb1'}}><strong>Refund and Cancellation Policy</strong></h2>
            <p className="mb-3">
              This refund and cancellation policy outlines how you can cancel or seek a refund for a product/service
              that you have purchased through the Platform. Under this policy:
            </p>
            <ol className="mb-4">
              <li className="mb-2">
                Cancellations will only be considered if the request is made within <strong>7 day</strong> of placing the order. However, cancellation requests may not be entertained if the orders have been communicated to such sellers/merchants listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. In such an event, you may choose to reject the product at the doorstep.
              </li>
              <li className="mb-2">
                <strong>SWEETY JEWELS PRIVATE LIMITED</strong> does not accept cancellation requests for perishable items like flowers, eatables, etc. However, a refund/replacement can be made if the user establishes that the quality of the product delivered is not good.
              </li>
              <li className="mb-2">
                In case of receipt of damaged or defective items, please report to our customer service team. The request would be entertained once the seller/merchant listed on the Platform has checked and determined the same at its own end. This should be reported within <strong>7 day</strong> of receipt of products. If you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within <strong>7 day</strong> of receiving the product. The customer service team will take an appropriate decision after reviewing your complaint.
              </li>
              <li className="mb-2">
                In case of complaints regarding the products that come with a warranty from the manufacturers, please refer the issue to them.
              </li>
              <li>
                In case of any refunds approved by <strong>SWEETY JEWELS PRIVATE LIMITED</strong>, it will take 5 days for the refund to be processed.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Refund;
