
import React, { useState, useEffect } from 'react';
import { Button, Form,  Row, Col, Image } from 'react-bootstrap';
import { AiOutlineMail, AiOutlineLock, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Logo1 from '../images/Logop.png';
import ReCAPTCHA from "react-google-recaptcha";
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from '../Footer/Footer';

const Login = () => {
  const [emailid, setEmailid] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false); // State to handle password visibility
  const [usersData, setUsersData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://sjpapi.prettywomen.in/API/v1/api/usersd');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUsersData(data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleLogin = async () => {
    const user = usersData.find(
      (user) => user.email === emailid && user.password === password
    );

    if (user) {
      sessionStorage.setItem('userType', user.user_type);
      sessionStorage.setItem('user_name', user.UserName);
      localStorage.setItem('userData', JSON.stringify(user));
      
      switch(user.user_type) {
        case 'admin':
          navigate('/dashboard');
          break;
        case 'account':
          navigate('/acdashboard');
          break;
        case 'logistics':
          navigate('/lodashboard');
          break;
        case 'StoctAdd':
          navigate('/dashboard');
          break;
        default:
          navigate('/login'); // Default redirect if user type is not matched
      }

      alert('Login successful!');
    } else {
      alert('Invalid credentials');
    }
  };



  return (
    <>
    <LandingHeader/>
    <div className="d-flex justify-content-center align-items-center"style={{fontFamily:'Arial, sans-serif'}} >
      <Row style={{ fontFamily: 'Nexa, sans-serif' }}>
        <Col className=" align-items-center p-5 bg-light" >
        <div className="d-flex align-items-center">
            <Image src={Logo1} alt="Logo" width={150} rounded className="me-5" />
            <h6 className="mb-0">User Login</h6>
          </div>
         
          <Form >
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <div className="input-group">
                <span className="input-group-text"><AiOutlineMail /></span>
                <Form.Control 
                  type="email" 
                  placeholder="Username@gmail.com" 
                  value={emailid}
                  onChange={(e) => setEmailid(e.target.value)} 
                />
              </div>
            </Form.Group>
            <Form.Group controlId="password" className="mb-4">
              <Form.Label>Password</Form.Label>
              <div className="input-group">
                <span className="input-group-text"><AiOutlineLock /></span>
                <Form.Control 
                  type={passwordVisible ? 'text' : 'password'}  // Toggle between text and password type
                  placeholder="············" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} 
                />
                <span 
                  className="input-group-text"
                  onClick={() => setPasswordVisible(!passwordVisible)}  // Toggle visibility
                  style={{ cursor: 'pointer' }}
                >
                  {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </Form.Group>
            <ReCAPTCHA className='mb-3'
              sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU"             
           />
            <Button variant="dark" className="w-100 mb-3" onClick={handleLogin}>Login</Button>
          </Form>
        </Col>
      </Row>
    </div>
    <Footer/>
    </>
  );
};

export default Login;


