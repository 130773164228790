import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST } from './wishlistActionTypes';

const initialState = {
  wishlistItems: JSON.parse(localStorage.getItem('wishlistItems')) || [],
  totalQuantity: JSON.parse(localStorage.getItem('wishlistItems'))?.length || 0,
};

const wishlistReducer = (state = initialState, action) => {
  let updatedWishlistItems;

  switch (action.type) {
    case ADD_TO_WISHLIST:
      const existingIndex = state.wishlistItems.findIndex(item => item.productId === action.payload.productId);

      if (existingIndex !== -1) {
        return state; // Item already exists in wishlist
      } else {
        updatedWishlistItems = [...state.wishlistItems, action.payload];
        localStorage.setItem('wishlistItems', JSON.stringify(updatedWishlistItems));
        return {
          ...state,
          wishlistItems: updatedWishlistItems,
          totalQuantity: state.totalQuantity + 1,
        };
      }

    case REMOVE_FROM_WISHLIST:
      updatedWishlistItems = state.wishlistItems.filter(item => item.productId !== action.payload);
      localStorage.setItem('wishlistItems', JSON.stringify(updatedWishlistItems));
      return {
        ...state,
        wishlistItems: updatedWishlistItems,
        totalQuantity: state.totalQuantity - 1,
      };

    default:
      return state;
  }
};

export default wishlistReducer;
