
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { FiBox } from "react-icons/fi";
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
} from 'recharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Dashboard = () => {
  const [productCount, setProductCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [categorySalesData, setCategorySalesData] = useState([]); // New state for sales data
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
        setProducts(response.data);
        setProductCount(response.data.length);
      } catch (err) {
        console.error('Failed to fetch products:', err);
      }
    };



    const fetchTransactions = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details');
        setTransactions(response.data);
        setAlertCount(response.data.length);
     
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    const fetchCustomers = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/customer');
        setCustomers(response.data);
        setCustomerCount(response.data.length);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/ap/subcategories');
        setCategoriesList(response.data);
        setCategoryCount(response.data.length);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchProducts();
    fetchTransactions();
    fetchCustomers();
    fetchCategories();
  }, []);



  const [subcategoryData, setSubcategoryData] = useState([]);
  const aggregateSubcategoryCounts = (products) => {
    const counts = {};
    products.forEach(product => {
      const subcategory = product.subcategory; // Adjust based on your data structure
      if (counts[subcategory]) {
        counts[subcategory]++;
      } else {
        counts[subcategory] = 1;
      }
    });
    return Object.entries(counts).map(([name, value]) => ({ name, value }));
  };

  useEffect(() => {
    if (products.length > 0) {
      const aggregatedData = aggregateSubcategoryCounts(products);
      setSubcategoryData(aggregatedData);
    }
  }, [products]);

  const getColor = (index) => {
    const colors = [
      '#b42c2c', 
      '#e17a70', 
      '#d09691', 
      '#f0c0a8', 
      '#a3c4e0', 
      '#78c7d2', 
      '#ffb84d', 
      '#4caf50', 
      '#ffc107', 
      '#6c757d', 
    ];
    return colors[index % colors.length]; // Loop through colors if there are more segments than colors
  };



  const processGraphData = (data) => {
    const results = [];
    const groupedData = {};

    const filteredData = data.filter(order => {
      const orderDate = new Date(order.transactionCreatedAt);
      return (!startDate || orderDate >= startDate) && (!endDate || orderDate <= endDate);
    });

    filteredData.forEach((order) => {
      const date = new Date(order.transactionCreatedAt);
      const day = date.toLocaleDateString();
      const month = date.toLocaleString('default', { month: 'long', year: 'numeric' });
      const year = date.getFullYear();

      groupedData[day] = groupedData[day] || { orders: 0, totalAmount: 0, customers: new Set() };
      groupedData[day].orders += 1;
      groupedData[day].totalAmount += order.amount / 100;
      groupedData[day].customers.add(order.userId);

      groupedData[month] = groupedData[month] || { orders: 0, totalAmount: 0, customers: new Set() };
      groupedData[month].orders += 1;
      groupedData[month].totalAmount += order.amount / 100;
      groupedData[month].customers.add(order.userId);

      groupedData[year] = groupedData[year] || { orders: 0, totalAmount: 0, customers: new Set() };
      groupedData[year].orders += 1;
      groupedData[year].totalAmount += order.amount / 100;
      groupedData[year].customers.add(order.userId);
    });

    for (const key in groupedData) {
      results.push({
        name: key,
        orders: groupedData[key].orders,
        totalAmount: groupedData[key].totalAmount,
        customers: groupedData[key].customers.size,
      });
    }
    setGraphData(results);
  };

  useEffect(() => {
    processGraphData(transactions);
  }, [transactions, startDate, endDate]);






  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/total-quantity');
        setCategoryData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <div className="d-flex">
      <Sidebar />
      <div className='w-100'>
        <Header />
        <div className="container mt-4" style={{ fontFamily: 'Arial, sans-serif' }}>
          <h3>Pretty Women Admin Dashboard</h3>

          <div className="row mt-4">
            <DashboardCard title="PRODUCTS" count={productCount} icon={<BsFillArchiveFill style={{ cursor: 'pointer' }} />} backgroundColor="#d09691" />
            <DashboardCard title="CATEGORIES" count={categoryCount} icon={<BsFillGrid3X3GapFill style={{ cursor: 'pointer' }} />} backgroundColor="#e17a70" />
            <DashboardCard title="CUSTOMERS" count={customerCount} icon={<BsPeopleFill style={{ cursor: 'pointer' }} />} backgroundColor="#b36d62" />
            <DashboardCard title="ORDERS" count={alertCount} icon={<FiBox style={{ cursor: 'pointer' }} />} backgroundColor="#b42c2c" />
          </div>

          <div className="container mt-4">
            <div className="row">
               <div className="col">
            <h6>Subcategory Overview</h6>
            <div style={{ width: '100%', height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={subcategoryData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={100}
                    fill="#b36d62"
                    dataKey="value"
                  >
                    {subcategoryData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(index)} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>

              <div className="col mt-4">
                <h4>Categories</h4>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Subcategory</th>
                        <th>Available</th>
                        <th>Sales</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryData.map(item => (
                        <tr key={item.subcategory}>
                          <td>{item.subcategory}</td>
                          <td>{item.totalQuantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Date Range Selection */}
          <div className="d-flex align-items-center mb-4">
            <label className="me-2">From:</label>
            <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
            <label className="me-2 ms-3">To:</label>
            <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
          </div>

          {/* Display Processed Graph Data */}
          <div className="mt-4">
            <h4>Processed Data Over Time</h4>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="orders" fill="#b42c2c">
                  <LabelList dataKey="orders" position="top" />
                </Bar>
                <Bar dataKey="totalAmount" fill="green">
                  <LabelList dataKey="totalAmount" position="top" />
                </Bar>
                <Bar dataKey="customers" fill="#b36d62">
                  <LabelList dataKey="customers" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardCard = ({ title, count, icon, backgroundColor }) => (
  <div className="col-md-3 mb-4">
    <div className="card" style={{ backgroundColor }}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{title}</h5>
          {icon}
        </div>
        <h1 className="mt-3">{count}</h1>
      </div>
    </div>
  </div>
);

export default Dashboard;


