import React from 'react';
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Return = () => {
  return (
    <>
      <LandingHeader />
      <div className="container mt-5" style={{fontFamily:'Arial, sans-serif',fontSize:'13px'}}>
        <div className="row">
          <div className="col-md-12">
            <h2 className="mb-4 text-center" style={{fontSize:'50px', color: '#d47bb1'}}><strong>RETURN POLICY</strong></h2>
            <p className="mb-3">
              We offer refund / exchange within the first <strong>1 day</strong> from the date of your purchase. If <strong>1 day</strong> has passed since your purchase, you will not be offered a return, exchange, or refund of any kind. In order to become eligible for a return or exchange:
            </p>
            <ul className="mb-3">
              <li>The purchased item should be unused and in the same condition as you received it.</li>
              <li>The item must have original packaging.</li>
              <li>If the item was purchased on sale, it may not be eligible for a return / exchange.</li>
              <li>Items are replaced by us (based on an exchange request) only if they are found defective or damaged.</li>
              <li>Return of Product at the risk of Customer and have to send through paid Courier Service only.</li>
            </ul>
            <p className="mb-3">
              You agree that there may be certain categories of products/items that are exempt from returns or refunds. Such categories will be identified to you at the time of purchase. For accepted exchange/return requests, once your returned product/item is received and inspected by us, we will send you an email to notify you about the receipt of the returned/exchanged product. Further, if the return/exchange is approved after the quality check, your request will be processed in accordance with our policies.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Return;
