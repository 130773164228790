
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { useEffect, useState } from 'react';
import Footer from '../Footer/Footer.jsx';
import LandingHeader from './LandingHeader.js';
import A1 from '../images/pw11.jpg';
import A2 from '../images/pw22.jpg';
import B1 from '../images/R1.jpg';
import B2 from '../images/N1.jpg';
import B3 from '../images/B11.jpg';
import B4 from '../images/E1.jpg';
import Y1 from '../images/Y1.jpg';
import './LandingPage.css';
import ProductCart from '../Cart/productCart';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { FaChevronCircleRight, FaCopy } from 'react-icons/fa'; // Import the copy icon



const LandingPage = () => {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [visibleCount, setVisibleCount] = useState(20);
  const [showModal, setShowModal] = useState(true);
  const [email, setEmail] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCouponCode, setShowCouponCode] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const subcategory = queryParams.get('subcategory');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
        const allProducts = response.data;

        const filtered = subcategory
          ? allProducts.filter(product => product.subcategory === subcategory.trim())
          : allProducts;
        setProducts(filtered);
      } catch (err) {
        console.error('Failed to fetch products:', err);
      }
    };

    fetchProducts();

    const isLoggedIn = !!localStorage.getItem('userToken');
    const hasSeenModal = localStorage.getItem('hasSeenModal');


    if (!hasSeenModal && !isLoggedIn) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [subcategory]);


  const closeModalPermanently = () => {
    setShowModal(false);
    localStorage.setItem('hasSeenModal', 'true'); // Mark the modal as seen
  };



  const filteredProducts = products.filter(product => {
    const lowerSearch = search.toLowerCase();
    return (
      (product.pname || '').toLowerCase().includes(lowerSearch) ||
      (product.category || '').toLowerCase().includes(lowerSearch) ||
      (product.subcategory || '').toLowerCase().includes(lowerSearch) ||
      (product.discount || '').toString().toLowerCase().includes(lowerSearch) ||
      (product.pcolor || '').toLowerCase().includes(lowerSearch)
    );
  });

  const productsToShow = filteredProducts.slice(0, visibleCount);

  const loadMoreProducts = () => {
    setVisibleCount(prevCount => prevCount + 20);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClaimDiscount = async () => {
    if (!email) {
      alert('Please enter your email address.');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/coupon', { email });
      setCouponCode(response.data.couponCode);
      setShowCouponCode(true);
      setShowModal(false);
    } catch (err) {
      console.error('Failed to claim discount:', err);
      alert('This email address has already been used for a coupon code.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Copy coupon code to clipboard
  const copyToClipboard = () => {
    if (couponCode) {
      navigator.clipboard.writeText(couponCode);
      alert('Coupon code copied to clipboard!');
    } else {
      alert('No coupon code to copy!');
    }
  };


  const [offer, setOffer] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    fetchOffers();
  }, []);

  const fetchOffers = async () => {
    try {
      const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/offers/ji'); // Fetch offers from the backend
      if (response.data && response.data.length > 0) {
        setOffer(response.data[0].offer); // Access the offer correctly
        setImageUrl(response.data[0].offerImg);
      } else {
        console.warn('No offers found');
      }
    } catch (error) {
      console.error('Error fetching offers:', error);
    }
  };


  return (
    <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
      {/* Modal Popup */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-lg modal-dialog-centered container ">
            <div className="modal-content moda item1" >
              <div className="modal-header">
                <h5>Get {offer}% OFF on Your First Order</h5>
                <button type="button" className="btn-close" onClick={closeModalPermanently}></button>
              </div>
              <div className="modal-body">
                {showCouponCode ? (
                  <div>
                    <p>Your coupon code is: <strong>{couponCode}</strong></p>
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={copyToClipboard}
                      style={{ marginLeft: '10px' }}
                    >
                      <FaCopy />
                    </button>
                    <p>Use this code at checkout to receive {offer}% off your first order.</p>
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <div>
                    <p>Sign up and unlock your instant discount.</p>
                    <div className="mb-2">
                      <label htmlFor="email" className="form-label">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={handleClaimDiscount}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Claiming...' : 'Claim Discount'}
                    </button>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <small>You are signing up to receive communication via email and can unsubscribe at any time.</small>
              </div>
            </div>
            <div className='item2'>
              {imageUrl ? <img src={imageUrl} className='offer-image ' style={{ width: '300px' }} alt="Offer" /> : 'Loading...'}
            </div>
          </div>
        </div>

      )}




      {/* <div>
        {imageUrl ? <img src={imageUrl} style={{ width: '195px' }} alt="Offer" /> : 'Loading...'}
      </div> */}

      <LandingHeader setSearch={setSearch} />
      <div className='image-overlay'>
        <div className='background-images'>
          <img src={A2} alt="A2 image" className='img-fluid' />
          <img src={A1} alt="A1 image" className='img-fluid' />
        </div>
        <h1><strong>CREATING BRIDGES BETWEEN CULTURES</strong></h1>
        <Link to='/product'>
          <button className='shop-now'><strong>SHOP NOW</strong></button>
        </Link>
      </div>



      <div className="text-center mt-5">
        <h1 className="font-weight-bold" style={{ color: ' #d47bb1' }}><strong>SHOP THE COLLECTION</strong></h1>
      </div>

      <div className="container-images mt-5 d-flex flex-row align-items-center justify-content-center">
        <div className="main-image">
          <img src={Y1} alt="Y1 image" className="y1 img-fluid responsive-img" />
        </div>
        <div className="image-column">
          <div className="mb-3">
            <a href="#">
              <img src={B1} className="a1-image img-fluid" alt="Rings" />
            </a>
          </div>
          <div className="mb-3">
            <a href="#">
              <img src={B2} className="a1-image img-fluid" alt="Necklaces" />
            </a>
          </div>
          <div className="mb-3">
            <a href="#">
              <img src={B4} className="a1-image img-fluid" alt="Brace" />
            </a>
          </div>
          <div>
            <a href="#">
              <img src={B3} className="a1-image img-fluid" alt="Earrings" />
            </a>
          </div>
        </div>
      </div>


      <div className="text-center mt-5">
        <h1 className="font-weight-bold" style={{ color: ' #d47bb1' }}><strong>OUR PRODUCTS</strong></h1>
      </div>


      <div className='p-3 mt-5'>
        <div className='container'>
          <div className='row bg-light justify-content-center'>
            {productsToShow.length > 0 ? (
              productsToShow.map((product) => (
                <div className='col-6 col-md-4 col-lg-3 d-flex align-items-center' key={product.id}>
                  <ProductCart data={product} />
                </div>
              ))
            ) : (
              <p className='text-center'>No products found</p>
            )}
          </div>

          {filteredProducts.length > visibleCount && (
            <div className='text-end mt-5'>
              <a
                onClick={loadMoreProducts}
                style={{ fontSize: '25px', color: '#ff5722', cursor: 'pointer' }}
              >
                <strong>View More</strong> <FaChevronCircleRight />
              </a>
            </div>
          )}
        </div>
      </div>
      <div className='mt-5'></div>
      <Footer />
    </div>
  );
};

export default LandingPage;
