// import React from 'react';
// import { Route, Navigate } from 'react-router-dom';

// const PrivateRoute = ({ element: Element, isLoggedIn, ...rest }) => {
//   return isLoggedIn ? (
//     <Route {...rest} element={<Element />} />
//   ) : (
//     <Navigate to="/login" replace />
//   );
// };

// export default PrivateRoute;

import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem("userData") !== null;

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;



// import React from "react";
// import { Navigate } from "react-router-dom";


// const ProtectedRoute = ({ element }) => {
//   // Check if user is authenticated
//   const userData = localStorage.getItem("userData");
//   const customerEmails = localStorage.getItem("customerEmails");

//   const isAuthenticated = userData !== null && customerEmails !== null;

//   return isAuthenticated ? element : <Navigate to="/login" />;
// };

// export default ProtectedRoute;