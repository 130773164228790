import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Image } from 'react-bootstrap';
import Logo1 from '../Pages/Logo1.png';
import ReCAPTCHA from "react-google-recaptcha";
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from '../Footer/Footer';


const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    
    try {
      const response = await fetch(`https://sjpapi.prettywomen.in/API/v1/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: newPassword }),
      });
    
      const data = await response.json();
    
      if (response.ok) {
        setSuccess('Password reset successfully.');
        setError('');
        setNewPassword('');
        setConfirmPassword('');
        setTimeout(() => navigate('/login'), 2000); // Redirect after 2 seconds
      } else {
        setError(data.error || 'Failed to reset password');
        setSuccess('');
      }
    } catch (error) {
      console.error('Reset password error:', error);
      setError('Failed to reset password');
      setSuccess('');
    }
  };

  return (
    <>
    <LandingHeader/>
    <div className="d-flex justify-content-center align-items-center min-vh-100 "style={{backgroundColor:'#fae3df'}} >
      <Row style={{ fontFamily: 'Nexa, sans-serif' }}>
        <Col className=" align-items-center p-5 rounded shadow bg-light" >
        <div className="d-flex align-items-center">
            <Image src={Logo1} alt="Logo" width={150} rounded className="me-5" />
            <h6 className="mb-0">Reset Password</h6>
          </div>
         
          <Form onSubmit={handleResetPassword}>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="confirmPassword" className="mt-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control className='mb-3'
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>
            <ReCAPTCHA className='mb-2'
              sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU"             
           />
            <Button type="submit" variant="danger btn-sm" className="mt-3">
              Save New Password
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
    <Footer/>
    </>
  );
};

export default ResetPassword;
