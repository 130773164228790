import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import axios from 'axios';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';

const Logistics = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [dispatchDate, setDispatchDate] = useState('');
  const [trackingId, setTrackingId] = useState('');
  const [addresses, setAddresses] = useState({ billingAddress: {} });
  const [orderStatuses, setOrderStatuses] = useState({});
  const [dispatchInfo, setDispatchInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(20);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const formRef = useRef(null);

  // Fetch orders and their statuses on page load
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersResponse = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details');
        const ordersData = ordersResponse.data;
        console.log('Orders Response:', ordersData);

        const statuses = {};
        await Promise.all(ordersData.map(async (order) => {
          try {
            const dispatchResponse = await axios.get(`https://sjpapi.prettywomen.in/API/v1/get-dispatch/${order.orderId}`);
            const dispatchData = dispatchResponse.data;

            statuses[order.orderId] = {
              status: dispatchData.flag === 1 ? 'success' : 'pending',
              trackingId: dispatchData.trackingId
            };
          } catch (error) {
            console.error(`Error fetching dispatch details for order ${order.orderId}:`, error);
            statuses[order.orderId] = { status: 'pending', trackingId: 'N/A' };
          }
        }));

        setOrderStatuses(statuses);
        setOrders(ordersData);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);


  useEffect(() => {
    if (selectedOrder) {
      const fetchAddresses = async () => {
        try {
          const response = await axios.get(`https://sjpapi.prettywomen.in/API/v1/api/get-addresses/${selectedOrder.userId}`);
          setAddresses(response.data);
        } catch (error) {
          console.error('Error fetching addresses:', error);
        }
      };

      const fetchDispatchDetails = async () => {
        try {
          const response = await axios.get(`https://sjpapi.prettywomen.in/API/v1/get-dispatch/${selectedOrder.orderId}`);
          const dispatchData = response.data;
          console.log('Dispatch Details Response:', dispatchData);

          setDispatchInfo(dispatchData);
         
          setDispatchDate(dispatchData.dispatchDate || '');
          setTrackingId(dispatchData.trackingId || '');

        } catch (error) {
          console.error('Error fetching dispatch details:', error);
          setDispatchInfo(null);
       
          setDispatchDate('');
          setTrackingId('');
        }
      };

      fetchAddresses();
      fetchDispatchDetails();
    }
  }, [selectedOrder]);

  // Handle order click
  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };




  const handleSubmitDispatchDetails = async (event) => {
    event.preventDefault();
  
    if (!dispatchDate || !trackingId) {
      alert('Please fill in all required fields.');
      return;
    }
  
    const orderDetails = {
      orderId: selectedOrder.orderId,
      userEmail: selectedOrder.email,
      dispatchDate,
      trackingId,
      customerName: selectedOrder.username,
    };


  
    try {
      // First API call
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/dispatch/submit-order', orderDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 200) {
        const { trackingId, flag } = response.data.insertedData;

  
        // Update order statuses
        setOrderStatuses(prevStatuses => {
          const updatedStatuses = { ...prevStatuses };
          updatedStatuses[selectedOrder.orderId] = {
            status: flag === 1 ? 'success' : 'pending',
            trackingId
          };
          return updatedStatuses;
        });
  
        alert('Order submitted successfully.');
  
        // Second API call using trackingId  https://emedha.com/pretty/order.php?mobile=${selectedOrder.userMobile}&name=${selectedOrder.username}&order=${selectedOrder.orderId}&trackno=${trackingId}
        const secondApiResponse = await axios.get(`https://emedha.com/pretty/dispatch.php?mobile=${selectedOrder.userMobile}&name=${selectedOrder.username}&order=${selectedOrder.orderId}&trackno=${trackingId}`);
  
        if (secondApiResponse.status === 200) {
          alert(' sent to WhatsApp successfully.');
        } else {
          throw new Error('Failed to send details to the second API.');
        }
  
        // Optionally reload or update state here
         window.location.reload();
        setShowModal(false);
        setSelectedOrder(null);
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error('Error submitting order:', error.response ? error.response.data : error.message);
      alert('Error submitting order: ' + (error.response?.data?.error || error.message));
    }
  };
  



  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders
    .slice()
    .sort((a, b) => new Date(b.transactionCreatedAt) - new Date(a.transactionCreatedAt))
    .slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(orders.length / ordersPerPage);

  const downloadExcel = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    console.log('Date Range:', start, end);
  
    const filteredOrders = orders.filter(order => {
      const orderDate = new Date(order.transactionCreatedAt);
      return orderDate >= start && orderDate <= end;
    });
  
    console.log('Filtered Orders for Excel:', filteredOrders);
  
    if (filteredOrders.length === 0) {
      alert('No orders found for the selected date range.');
      return;
    }
  
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredOrders);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
    XLSX.writeFile(workbook, 'orders.xlsx');
  };
  

  const downloadPDF = () => {
    const filteredOrders = orders.filter(order => {
      const orderDate = new Date(order.transactionCreatedAt);
      return orderDate >= new Date(startDate) && orderDate <= new Date(endDate);
    });

    console.log('Filtered Orders for PDF:', filteredOrders);

    if (filteredOrders.length === 0) {
      alert('No orders found for the selected date range.');
      return;
    }

    const doc = new jsPDF();
    const tableData = filteredOrders.map(order => ({
      OrderID: order.orderId,
      CustomerName: order.username,
      OrderDate: new Date(order.transactionCreatedAt).toLocaleDateString(),
      TrackingID: orderStatuses[order.orderId]?.trackingId || 'N/A',
      Status: orderStatuses[order.orderId]?.status || 'Pending',
    }));

    console.log('Table Data for PDF:', tableData);

    doc.autoTable({
      head: [['Order ID', 'Customer Name', 'Order Date', 'Tracking ID', 'Status']],
      body: tableData.map(item => [item.OrderID, item.CustomerName, item.OrderDate, item.TrackingID, item.Status]),
    });

    doc.save('orders.pdf');
  };


  const [userType, setUserType] = useState('');

  useEffect(() => {
    const type = sessionStorage.getItem('userType');
    setUserType(type);
  }, []);


  return (
    <div className="d-flex">
      <Sidebar />
      <div className="w-100">
        <Header />
        <div className="text-center mt-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
          <h4 className="mt-5">Logistics</h4>
          {userType === 'admin' && (
          <div className="mt-4 d-flex align-items-center justify-content-center">
            <label className="me-2">From:</label>
            <DatePicker selected={startDate} onChange={date => setStartDate(date)} className="form-control" />
            <label className="me-2 ms-3">To:</label>
            <DatePicker selected={endDate} onChange={date => setEndDate(date)} className="form-control" />
            <div className="ms-3">
              <Button onClick={downloadExcel} className="me-2 btn-sm btn-primary">Download Excel</Button>
              <Button onClick={downloadPDF} className="btn-sm btn-secondary">Download PDF</Button>
            </div>
          </div>
      )}
          {orders.length > 0 ? (
            <table className="table table-bordered table-striped mt-5">
              <thead className="table-light">
                <tr>
                  <th>Order Date</th>
                  <th>Customer Name</th>
                  <th>Transaction Id</th>
                  <th>Order ID</th>
                  <th>Tracking ID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders
                  .slice()
                  .sort((a, b) => new Date(b.transactionCreatedAt) - new Date(a.transactionCreatedAt))
                  .map(order => (
                    <tr key={order.transactionId}>
                      <td>{new Date(order.transactionCreatedAt).toLocaleDateString()}</td>
                      <td>{order.username}</td>
                      <td>{order.paymentTransactionId}</td>
                      <td>{order.orderId}</td>
                      <td>
                        {orderStatuses[order.orderId]?.status === 'success' ? orderStatuses[order.orderId].trackingId : 'N/A'}
                      </td>
                      <td>
                        <Button
                          variant={orderStatuses[order.orderId]?.status === 'success' ? 'success' : 'danger'}
                          className="btn-sm"
                          onClick={() => handleOrderClick(order)}
                        >
                          {orderStatuses[order.orderId]?.status === 'success' ? 'Success' : 'Pending'}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p>No orders available.</p>
          )}

          <div className="d-flex justify-content-center mt-3">
            <Button className='btn-sm me-2'
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>{`  ${currentPage} of ${totalPages} `}</span>
            <Button className='btn-sm ms-2'
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Order and Dispatch Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
              {selectedOrder && (
                <>
                  <p><strong>Order ID:</strong> {selectedOrder.orderId}</p>
                  <p><strong>Name:</strong> {selectedOrder.username} <strong>Email:</strong> {selectedOrder.email}</p>
                  <p><strong>Mobile:</strong> {selectedOrder.userMobile}</p>
                  <p><strong>Billing Address:</strong></p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div>House No: {addresses.billingAddress.houseNo}</div>
                      <div>Address: {addresses.billingAddress.address}</div>
                      <div>City: {addresses.billingAddress.city}</div>
                      <div>State: {addresses.billingAddress.state}</div>
                      <div>ZIP: {addresses.billingAddress.zip}</div>
                      <div>WhatsApp: {addresses.billingAddress.whatsapp}</div>
                    </div>
                    <div className="col-lg-6">
                      <p><strong>Product Details:</strong></p>
                      {selectedOrder.productId ? (
                        <div key={selectedOrder.productId}>
                          <img src={selectedOrder.productImage || 'default-image.png'} alt={selectedOrder.productName || 'Product Image'} style={{ width: '80px', height: '80px' }} />
                          <div>Name: {selectedOrder.productName || 'No name provided'}</div>
                          <div>Price: Rs. {selectedOrder.productPrice || '0.00'}</div>
                          <div>Style Code: {selectedOrder.stylecode || 'N/A'}</div>
                        </div>
                      ) : (
                        <div>No product details available.</div>
                      )}
                    </div>
                  </div>

                  <p className='mb-2'><strong>Order Date:</strong> {new Date(selectedOrder.transactionCreatedAt).toLocaleDateString()}</p>

                  {orderStatuses[selectedOrder.orderId]?.status === 'success' ? (
                    <div>
                      <h5>Dispatch Details:</h5>
                      <p><strong>Docket ID:</strong> {dispatchInfo?.trackingId}</p>
                    
                      <p><strong>Dispatch Date:</strong> {dispatchInfo?.dispatchDate}</p>
                    </div>
                  ) : (
                    <div>
                      <h5 className='text-center text-black'>Enter Dispatch Details</h5>
                      <Form ref={formRef} onSubmit={handleSubmitDispatchDetails}>
                        <Form.Group controlId="formOrderId">
                          <Form.Label>Order ID</Form.Label>
                          <Form.Control
                            type="text"
                            value={selectedOrder ? selectedOrder.orderId : ''}
                            readOnly
                          />
                        </Form.Group>
                        <Form.Group controlId="formCustomerEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            value={selectedOrder ? selectedOrder.email : ''}
                            readOnly
                          />
                        </Form.Group>
                        <Form.Group controlId="formTrackingId">
                          <Form.Label>Docket ID</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter tracking ID"
                            value={trackingId}
                            onChange={(e) => setTrackingId(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="formDispatchDate">
                          <Form.Label>Dispatch Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={dispatchDate}
                            min={new Date().toISOString().split('T')[0]}
                            onChange={(e) => setDispatchDate(e.target.value)}
                          />
                        </Form.Group>                    
                        <Button variant="primary btn-sm" type="submit">
                          Submit
                        </Button>
                      </Form>
                    </div>
                  )}
                </>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>

  );
};

export default Logistics;
